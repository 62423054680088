import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

//const Orders = () => {
const CampaignKeys = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(false);

  const [xlsData, setXlsData] = useState([]);

  const sort = 500;
  let pagination = Array(Math.ceil(keys.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [product, setProduct] = useState("all");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (keys) {
      setLoading(false);
      setTableData(keys.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [keys]);

  useEffect(() => {
    loadProducts();
  }, [dispatch]);

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(keys.slice(i * sort, (i + 1) * sort));
  };

  const loadProducts = async () => {
    const { data } = await axiosInstance.get("products");

    if (data.status) {
      setProducts(data.products);
    }
  };

  useEffect(() => {
    loadKeys();
  }, [match]);

  useEffect(() => {
    if (keys) {
      setLoading(false);
      setTableData(keys.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [keys]);

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const loadKeys = async () => {
    const { data } = await axiosInstance.get(
      "license/campaign/" + match.params.id
     
    );

    if (data.status) {
      setCampaign(data.campaign);
      setKeys(data.campaign.keys);
      document.getElementById("nav_title").innerHTML = data.campaign.name
        ? data.campaign.name
        : match.params.id;
    }
  };

  const onDeactive = async (code) => {};

  const initXlsData = async () => {
    var dummy = [];
    keys.map((data, i) => {
      dummy.push({
        sr_no: i + 1,
        id: data._id,
        key: data.key,
        product_id: data.productId,
        product_name: productName(data.productId),
        valid: data.valid,
        status: data.status,
        name: data.name,
        phone: data.phone,
        email: data.email,
        place: data.place,
        country: data.country,
        activate_at: data.activateAt
          ? format(new Date(data.activateAt), "dd/MM/yyyy H:mma")
          : "",
        expire_at: data.expireAt
          ? format(new Date(data.expireAt), "dd/MM/yyyy H:mma")
          : "",
          note:data.note??"",
        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  function keyStatus(d) {
    if (d.status == 0) {
     return  (<Badge variant="success light">
              Available
            </Badge>);
    }else if (d.status == 1) {
      return  (<Badge variant="secondary">
               Active
             </Badge>);
     }else if (d.status == 2) {
      return  (<Badge variant="danger light">
               Deactive
             </Badge>);
     }else if (d.status == 3) {
      return  (<Badge variant="danger light">
               Expire
             </Badge>);
     }else{
       return <></>
     }
  }

  return (
    <>
      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Licenses Keys</h4>

              <CSVLink
                data={xlsData}
                filename={"keys-" + match.params.id + ".csv"}
                className="btn btn-primary"
                target="_blank"
                asyncOnClick={true}
                onClick={async (event, done) => {
                  console.log("You click the link");
                  await initXlsData();
                  done(); // 👍🏻 You are stopping the handling of component
                }}
              >
                Export
              </CSVLink>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : keys.length == 0 ? (
                <p>No keys found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Key</th>
                          <th>User</th>
                          <th>Product</th>
                          <th>Campaign</th>
                          <th>Valid</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Expire</th>
                          <th>Note</th>
                          <th>Created</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{whiteSpace:"nowrap"}} onClick={() => {navigator.clipboard.writeText(d.key)}}>{d.key}</td>
                            <td>
                              {d.name}
                              <br />
                              <small>{d.phone}</small>
                              <br />
                              <small>{d.email}</small>
                              <br />
                              <small>{d.place}</small>
                              <br />
                              <small>{d.country}</small>
                            </td>

                            <td>{productName(d.productId)}</td>

                            <td>{campaign.name}</td>

                            <td>{d.valid} Days</td>
                            <td className="text-center">{keyStatus(d)}</td>

                            <td>
                              {d.activateAt
                                ? format(
                                    new Date(d.activateAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              {d.expireAt
                                ? format(
                                    new Date(d.expireAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>{d.note??""}</td>

                            <td>
                              {d.createdAt
                                ? format(
                                    new Date(d.createdAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onDeactive(d)}
                                  >
                                    Deactive
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Key</th>
                          <th>User</th>
                          <th>Product</th>
                          <th>Valid</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Expire</th>
                          <th>Note</th>
                          <th>Created</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to &nbsp;
                        {keys.length < (activePage + 1) * sort
                          ? keys.length
                          : (activePage + 1) * sort}
                        &nbsp;of {keys.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default CampaignKeys;
