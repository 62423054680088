import * as actionTypes from "./cardConstants";
import axiosInstance from '../../services/AxiosInstance';

export const getAllProducts = () => async (dispatch) => {
  try {
   
    const { data } = await axiosInstance.get("products");

    console.log("products - "+JSON.stringify(data));

    if(data.status){
      dispatch({
        type: "PRODUCTS",
        payload: data.products,
      });
    }else{
      dispatch({
        type: "PRODUCTS",
        payload: [],
      });
    }
  
  } catch (error) {
    dispatch({
      type: "PRODUCTS",
      payload: [],
    });
  }
};


export const getStatistics = () => async (dispatch) => {
  try {
   
    const { data } = await axiosInstance.get("admim/statistics");

    console.log("getStatistics - "+JSON.stringify(data));

    if(data.status){
      dispatch({
        type: "STATISTICS",
        payload: data.statistics,
      });
    }else{
      dispatch({
        type: "STATISTICS",
        payload: {},
      });
    }
  
  } catch (error) {
    dispatch({
      type: "STATISTICS",
      payload: {},
    });
  }
};


