import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";
import { format } from "date-fns";

import { TwitterPicker, SketchPicker, SliderPicker } from "react-color";

import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Row,
  Col,
  Form,
} from "react-bootstrap";

import { CSVLink } from "react-csv";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DTable from "../components/Table";

import noIamage from "../../images/no-image-uploaded.png";
import { getAllProducts } from "../../store/actions/licActions";

const AgentDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const [reseller, setReseller] = useState(null);
  const [members, setMembers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [agents, setAgents] = useState([]);

  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");
  const [loading, setLoading] = useState(false);

  const [keys, setKeys] = useState([]);
  const [errors, setErrors] = useState({});
  const [generateKeyModal, setGenerateKeyModal] = useState(false);

  const [keyQty, setKeyQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [note, setNote] = useState("");

  const [activeKey, setActiveKey] = useState(null);
  const [editKeyModal, setEditKeyModal] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [enable, setEnable] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const [status, setStatus] = useState("0");

  const [product, setProduct] = useState("all");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [softwareName, setSoftwareName] = useState("");
  const [description, setDescription] = useState("");

  const [helpContact, setHelpContact] = useState("");
  const [helpEmail, setHelpEmail] = useState("");

  const [siteLink, setSiteLink] = useState("");
  const [demoVideoLink, setDemoVideoLink] = useState("");

  const [showAd, setShowAd] = useState(false);
  const [adBannerLink, setBannerAdLink] = useState("");
  const [adLink, setAdLink] = useState("");

  const [rebrand, setRebrand] = useState(false);

  const [color, setColor] = useState({ hex: "#08094e" });

  const [filter, setFilter] = useState("all");

  const [editMemberModal, setEditMemberModal] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [version, setVersion] = useState(1);
  const [updateUrl, setUpdateUrl] = useState("");

  useEffect(() => {
    dispatch(getAllProducts());

    if(match.params.id){
      loadKeys();
      
    }
   
  }, [dispatch]);

  useEffect(() => {
   
    if(match.params.id){
      document.getElementById("nav_title").innerHTML = match.params.id;
    }
    
   
  }, [match]);

  useEffect(() => {
    if (keys) {
      setLoading(false);
    }
  }, [keys]);

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const onChangeLicStatus = async (lic, status) => {
    const { data } = await axiosInstance.post("license/change-status", {
      license_id: lic._id,
      status: status,
    });

    if (data.status === false) {
      //toast.error(data.message);
      swal("Change license Status", data.message, "error");
    } else {
      swal("Change license Status", data.message, "success");
      // setTimeout(() => {
      //   loadKeys();
      // }, 500);

      var dummy = [...keys];

      const index = dummy.findIndex((x) => x._id === lic._id);

      dummy[index].enable = status;
      setKeys(dummy);
      onClick(activePage);
    }
  };

  const onEditLic = async (lic) => {
    setActiveKey(lic);
    setName(lic.name);
    setEmail(lic.email);
    setPhone(lic.phone);
    setCity(lic.place);
    setCountry(lic.country);
    setActiveDate(lic.activateAt ?? "");
    setExpireDate(lic.expireAt ?? "");
    setValid(lic.valid);
    setEnable(lic.enable);
    setStatus(lic.status);
    setSelectedProduct(lic.productId);
    setEditKeyModal(true);
  };

  const onUpdateLicDetails = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (status != "0" && activeDate == "") {
      error = true;
      errorObj.activeDate = "Activate Date is Required";
    }

    if (status != "0" && expireDate == "") {
      error = true;
      errorObj.expireAt = "Expire Date is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("license/update-details", {
          license_id: activeKey._id,
          campaign_id: "",
          product_id: selectedProduct,
          enable: enable,
          status: Number(status),
          valid: Number(valid),

          activate_at: activeDate,
          expire_at: expireDate,

          name: name,
          email: email,
          phone: phone,
          place: city,
          country: country,

          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Update license", data.message, "error");
        } else {
          setEditKeyModal(false);
          //toast.success(data.message);
          swal("Update license", data.message, "success");

          setKeyQty("1");
          setValid("365");
          setCampaignName("");
          setNote("");
          setName("");
          setEmail("");
          setPhone("");
          setCity("");
          setCountry("");

          var dummy = [...keys];
          const index = dummy.findIndex((x) => x._id === activeKey._id);
          dummy[index].enable = enable;
          dummy[index].status = status;
          dummy[index].name = name;
          dummy[index].email = email;
          dummy[index].phone = phone;
          dummy[index].place = city;
          dummy[index].country = country;
          dummy[index].valid = valid;

          dummy[index].note = note;

          if (status == "0") {
            dummy[index].activateAt = null;
            dummy[index].expireAt = null;
          } else {
            dummy[index].activateAt = activeDate;
            dummy[index].expireAt = expireDate;
          }

          setKeys(dummy);
          onClick(activePage);
          // setTimeout(() => {
          //   loadKeys();
          // }, 500);
        }
      } catch (error) {}
    }
  };

  const initXlsData = async () => {
    const { data } = await axiosInstance.post("license/all-related", {
      filter: "all",
      product_id: product,
      search: search,
      page: 0,
      limit: maxPage * pageSize,
      license_key: match.params.id,
    });

    if (data.status) {
      console.log("total keys - " + data.keys.length);

      var dummy = [];
      data.keys.map((d, i) => {
        dummy.push({
          sr_no: i + 1,
          id: d.id,
          key: d.key,
          product_id: d.productId,
          product_name: productName(d.productId),
          valid: d.valid,
          status: d.status,
          name: d.name,
          phone: d.phone,
          email: d.email,
          place: d.place,
          country: d.country,
          activate_at: d.activateAt
            ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
            : "",
          expire_at: d.expireAt
            ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
            : "",
          note: d.note ?? "",
          created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactive</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadKeys(filter);
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadKeys(filter);
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loadKeys = async (filter) => {
    setLoading(true);
    const { data } = await axiosInstance.post("license/all-related", {
      filter: filter ?? "all",
      product_id: product,
      license_key: match.params.id,
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setKeys(data.keys);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Key",
      accessor: "key",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.key);
            }}
          >
            {d.key}
          </span>
        );
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "User",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            
            {d.name}
            <br />
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.place}</small>
            <br />
            <small>{d.country}</small>
          </div>
        );
      },
    },

    {
      Header: "Reseller",
      accessor: "reserllerId",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return d.resellerId ? (
          <div>
            <Link to={"/reseller/" + d.resellerId.id}>{d.resellerId.name}</Link>
            <br />
            <small>{d.resellerId.phone}</small>
            <br />
            <small>{d.resellerId.email}</small>
          </div>
        ) : (
          <div>No</div>
        );
      },
    },

    {
      Header: "Badge",
      accessor: "badge",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <>
            {d.badge.map((x) => {
              return <Badge variant="primary light">{x}</Badge>;
            })}
          </>
        );
      },
    },

    {
      Header: "Product",
      accessor: "productId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return productName(d.productId);
      },
    },

    {
      Header: "Campaign",
      accessor: "campaignId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.campaignId ? d.campaignId.name : "";
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
    },
    {
      Header: "Ip",
      accessor: "ip",
      sort: true,
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {d.enable ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, false)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, true)}
                >
                  Enable
                </Dropdown.Item>
              )}

              <Dropdown.Item as="button" onClick={(e) => assignReseller(d)}>
                {d.resellerId ? "Change Reseller" : "Assign to Reseller"}
              </Dropdown.Item>

              {d.resellerId ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => removeResellerFromKey(d)}
                >
                  Remove Reseller
                </Dropdown.Item>
              ) : (
                <></>
              )}

              <Dropdown.Item as="button" onClick={(e) => onEditLic(d)}>
                Edit
              </Dropdown.Item>

             
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const changeKeyFilter = async (filter) => {
    await setFilter(filter);
    loadKeys(filter);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  return (
    <>
      <Modal
        size={"xl"}
        className="modal fade"
        show={editKeyModal}
        onHide={setEditKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onUpdateLicDetails}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  License Details -{" "}
                  {activeKey ? (
                    <small className="b">{activeKey.key}</small>
                  ) : (
                    ""
                  )}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>

              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label
                            id=""
                            column
                            md="4"
                            className="required-field"
                          >
                            Is Enable
                          </Form.Label>
                          <Col md="7">
                            <Form.Check
                              type="checkbox"
                              id={`isActive`}
                              label={``}
                              value={enable}
                              checked={enable}
                              onClick={(e) => setEnable(!enable)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Product:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={selectedProduct}
                              onChange={(e) =>
                                setSelectedProduct(e.target.value)
                              }
                            >
                              <option value="">Choose Product</option>
                              {products.map((p, i) => {
                                return (
                                  <option key={i} value={p.id}>
                                    {i + 1} - {p.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.product && (
                              <div className="text-danger fs-12">
                                {errors.product}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <h4 className="h4 text-dark mt-4">Key Details</h4>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Valid:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="number"
                              value={valid}
                              placeholder="Enter Days"
                              className="form-control"
                              required="true"
                              min={0}
                              max={36500}
                              onChange={(e) => {
                                setValid(e.target.value);

                                var currentDate = activeDate; //Date.now();
                                var ed = moment(currentDate);
                                ed.add(e.target.value, "days").format(
                                  "YYYY-MM-DD hh:mm"
                                );

                                setExpireDate(ed);
                              }}
                            />{" "}
                            {errors.valid && (
                              <div className="text-danger fs-12">
                                {errors.valid}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Status:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="0">Available</option>
                              <option value="1">Active</option>
                              {/*  <option value="2">Deactive</option> */}
                              <option value="3">Expire</option>
                            </select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    {status != "0" ? (
                      <Row>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Activation Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Activation Date"
                                  value={activeDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setActiveDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate = moment(date).startOf("day");
                                    var endDate =
                                      moment(expireDate).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />

                                {/* <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Activated Date"
                                format="dd/MM/yyyy hh:mm"
                                placeholder="dd/MM/yyyy hh:mm"
                                value={activeDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setActiveDate(date)}
                              /> */}
                              </MuiPickersUtilsProvider>

                              {errors.activeDate && (
                                <div className="text-danger fs-12">
                                  {errors.activeDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Expire Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Expire Date"
                                  value={expireDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setExpireDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate =
                                      moment(activeDate).startOf("day");
                                    var endDate = moment(date).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />
                              </MuiPickersUtilsProvider>

                              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Expired Date"
                                format="dd/MM/yyyy hh:mm"
                                placeholder="dd/MM/yyyy hh:mm"
                                value={expireDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setExpireDate(date)}
                              />
                            </MuiPickersUtilsProvider> */}

                              {errors.expireDate && (
                                <div className="text-danger fs-12">
                                  {errors.expireDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}

                    <h4 className="h4 text-dark mt-4">User Details</h4>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Name:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="text"
                              value={name}
                              placeholder="Enter Name"
                              className="form-control"
                              onChange={(e) => setName(e.target.value)}
                            />{" "}
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            HID:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="text"
                              value={activeKey ? activeKey.deviceId ?? "" : ""}
                              placeholder="Hardware ID"
                              className="form-control"
                              readOnly
                            />{" "}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Email:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="email"
                              value={email}
                              placeholder="Enter Email Id"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />{" "}
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Phone Number :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            {errors.phone && (
                              <div className="text-danger fs-12">
                                {errors.phone}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Place/City :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              placeholder="Enter City Name"
                              className="form-control"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                            {errors.city && (
                              <div className="text-danger fs-12">
                                {errors.city}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Country :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              placeholder="Enter Country Name"
                              className="form-control"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                            {errors.country && (
                              <div className="text-danger fs-12">
                                {errors.country}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Note :
                          </Form.Label>
                          <Col md="7">
                            <textarea
                              rows={3}
                              placeholder="Enter Note"
                              className="form-control"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditKeyModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"> </i>
                  Close
                </button>

                <button type="submit" className="btn btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

     
      <Fragment>
       
           
                <div className="row">
                 

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title mr-auto"> Related License Key</h4>

                        <div className="input-group search-area d-lg-inline-flex mr-2">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (searchTimeout) {
                                clearTimeout(searchTimeout);
                              }
                              loadCampaigns();
                            }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </form>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <Dropdown className="mr-2">
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {filter} Keys
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("all")}
                            >
                              All Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("available")}
                            >
                              Available Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("active")}
                            >
                              Active Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("expired")}
                            >
                              Expired Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("trial")}
                            >
                              Trial Keys
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="card-body">
                        {loading ? (
                          <div className="text-center mt-4">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : keys.length == 0 ? (
                          <p>No License Keys Found</p>
                        ) : keys.length == 0 ? (
                          <p>No keys found</p>
                        ) : (
                          <DTable
                            columns={columns}
                            data={keys}
                            pageSize={pageSize}
                            isSortable={true}
                            pagination={false}
                            pageSizes={pageSizes}
                            page={page}
                            maxPage={maxPage}
                            handlePageChange={handlePageChange}
                            handlePageSizeChange={handlePageSizeChange}
                          />

                          
                        )}
                      </div>
                    </div>
                  </div>

                 
                </div>
             
           
          
       
      </Fragment>
    </>
  );
};

export default AgentDetails;
