import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    /// Path
    var fullPath = window.location.pathname;
    let path = fullPath.split("/");
    path = path[path.length - 1];

    /// Active menu
    let deshBoard = [""],
      orders = ["orders"],
      keys = ["license-keys/all","available","active","expired"],
      vouchers = ["voucher"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li
              className={`${
                path === "" || path === "dashboard" ? "mm-active" : ""
              }`}
            >
              <Link to="/" className="ai-icon">
                <i className="flaticon-381-home-2"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            {/*  <li className={`${path === "users" ? "mm-active" : ""}`}>
                     <Link
                        to="/users"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Users</span>
                     </Link>
                  </li> */}

            <li className={`${fullPath.includes("resellers/all") ? "mm-active" : ""}`}>
              <Link to="/resellers/all" className="ai-icon">
                <i className="flaticon-381-user-7"></i>
                <span className="nav-text">Resellers</span>
              </Link>
            </li>

            <li className={`${fullPath.includes("resellers/master") ? "mm-active" : ""}`}>
              <Link to="/resellers/master" className="ai-icon">
                <i className="flaticon-381-user-7"></i>
                <span className="nav-text">Master Resellers</span>
              </Link>
            </li>

            <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
              <Link to="/orders" className="ai-icon">
                <i className="flaticon-381-box"></i>
                <span className="nav-text">Orders</span>
              </Link>
            </li>

            <li className={`${path === "wallet-record" ? "mm-active" : ""}`}>
              <Link to="/wallet-record" className="ai-icon">
                <i className="flaticon-381-id-card"></i>
                <span className="nav-text">Wallet Record</span>
              </Link>
            </li>

            {/*  <li className={`${path === "feedbacks" ? "mm-active" : ""}`}>
                     <Link
                        to="/feedbacks"
                        className="ai-icon"
                        
                     >
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">Feedbacks</span>
                     </Link>
                  </li> */}

            {/*   <li className={`${path === "cards" ? "mm-active" : ""}`}>
                     <Link
                        to="/cards"
                        className="ai-icon"
                        
                     >
                       <i className="flaticon-381-id-card-1"></i>
                        
                        <span className="nav-text">Cards</span>
                     </Link>
                  </li> */}

            {/* 
                  <li className={`${path === "notifications" ? "mm-active" : ""}`}>
                     <Link
                        to="/notifications"
                        className="ai-icon"
                     >
                         <i className="flaticon-381-ring-1"></i>
                        <span className="nav-text">Notifications</span>
                     </Link>
                  </li> */}

            <li
              className={`${
                path === "campaign" || path.startsWith("campaign-keys")
                  ? "mm-active"
                  : ""
              }`}
            >
              <Link to="/campaign" className="ai-icon">
                <i className="flaticon-381-promotion"></i>
                <span className="nav-text">Campaigns</span>
              </Link>
            </li>

            <li className={`${fullPath.includes("license-keys") ? "mm-active" : ""}`}>
              <Link className="ai-icon has-arrow" to="#">
                <i className="flaticon-381-key"></i>

                <span className="nav-text">License Keys</span>
              </Link>
              <ul className={`${fullPath.includes("license-keys") ? "mm-show" : ""}`}>
                <li>
                  <Link
                    className={`${
                      fullPath.includes("license-keys/all") ? "mm-active" : ""
                    }`}
                    to="/license-keys/all"
                  >
                    All Keys
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/available") ? "mm-active" : ""
                    }`}
                    to="/license-keys/available"
                  >
                    Available
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/active") ? "mm-active" : ""
                    }`}
                    to="/license-keys/active"
                  >
                    Activated
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/expired") ? "mm-active" : ""
                    }`}
                    to="/license-keys/expired"
                  >
                    Expired
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/trial") ? "mm-active" : ""
                    }`}
                    to="/license-keys/trial"
                  >
                    Trial
                  </Link>

                </li>
              </ul>
            </li>


            <li className={`${fullPath.includes("addons") ? "mm-active" : ""}`}>
              <Link className="ai-icon has-arrow" to="#">
                <i className="flaticon-381-key"></i>

                <span className="nav-text">Addons</span>
              </Link>
              <ul className={`${fullPath.includes("addons") ? "mm-show" : ""}`}>
                <li>
                  <Link
                    className={`${
                      fullPath.includes("addons/all") ? "mm-active" : ""
                    }`}
                    to="/addons/all"
                  >
                    All Addons
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("addons/available") ? "mm-active" : ""
                    }`}
                    to="/addons/available"
                  >
                    Available
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("addons/active") ? "mm-active" : ""
                    }`}
                    to="/addons/active"
                  >
                    Activated
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("addons/expired") ? "mm-active" : ""
                    }`}
                    to="/addons/expired"
                  >
                    Expired
                  </Link>

                </li>
              </ul>
            </li>


            <li className={`${fullPath.includes("vouchers") ? "mm-active" : ""}`}>
              
              
              <Link className="ai-icon has-arrow" to="#">
                <i className="flaticon-381-gift"></i>

                <span className="nav-text">Vouchers</span>
              </Link>


              <ul className={`${fullPath.includes("vouchers") ? "mm-show" : ""}`}>
                <li>
                  <Link
                    className={`${
                      fullPath.includes("vouchers/all") ? "mm-active" : ""
                    }`}
                    to="/vouchers/all"
                  >
                    All Vouchers
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("vouchers/unused") ? "mm-active" : ""
                    }`}
                    to="/vouchers/unused"
                  >
                    Unused
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("vouchers/redeemed") ? "mm-active" : ""
                    }`}
                    to="/vouchers/redeemed"
                  >
                    Redeemed
                  </Link>

                 
                </li>
              </ul>
            </li>

            <li className={`${path === "setting" ? "mm-active" : ""}`}>
              <Link to="/setting" className="ai-icon">
                <i className="flaticon-381-settings-6"></i>

                <span className="nav-text">Setting</span>
              </Link>
            </li>
          </MM>

          <div className="copyright">
            <p>
              <strong>DBM Admin</strong> ©All Rights Reserved
            </p>
            <p>By Bit Beast 2022</p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
