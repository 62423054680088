import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Card,
  Form,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { useLocation } from "react-router-dom";
import { getAllProducts } from "../../store/actions/licActions";

//dummy data
//import { records as data, expandableRecords } from './data';

//const Orders = () => {
const ProductSetting = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");
  const [reseller, setReseller] = useState(null);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [activeReseller, setActiveReseller] = useState(null);

  const [activeToggle, setActiveToggle] = useState("posts");
  const [sendMessage, setSendMessage] = useState(false);
  const [replay, setReplay] = useState(false);

  const [productData, setProductData] = useState([]);
  const [test, setTest] = useState(true);
  const [variations, setVariations] = useState([]);

  //product setting
  const [productSettingModal, setProductSettingModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const [productName, setProductName] = useState("");
  const [productEnable, setProductEnable] = useState(false);

  const [websiteUrl, setWebsiteUrl] = useState("");
  const [downloadPageUrl, setDownloadPageUrl] = useState("");

  let errorsObj = {};

  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [androidUpdate, setAndroidUpdate] = useState(true);
  const [androidVersion, setAndroidVersion] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  const [newsUpdate, setNewsUpdate] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [frontLink, setFrontLink] = useState("");
  const [backImage, setBackImage] = useState("");
  const [backLink, setBackLink] = useState("");

  useEffect(() => {
    if (products) {
      loadProductSetting();
    }
  }, [products]);

  useEffect(() => {
    loadDetails(match.params.id);
  }, [match]);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const loadProductSetting = async () => {
    const { data } = await axiosInstance.post("admin/product-setting", {
      reseller_id: match.params.id,
    });

    if (data.status) {
      setProductData(
        products.map((x) => {
          const product = data.products.find((p) => p.productId == x._id);

          if (product) {
            return {
              id: x._id,
              original: x.name,
              name: product.name,
              enable: product.enable,
              variations: product.variations ?? [],
            };
          } else {
            return {
              id: x._id,
              original: x.name,
              name: "",
              enable: true,
              variations: x.variations ?? [],
            };
          }
        })
      );
    } else {
      setProductData(
        products.map((x) => {
          return {
            id: x._id,
            original: x.name,
            name: "",
            enable: false,
            variations: x.variations ?? [],
          };
        })
      );
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const onChangeVariationPrice = (i, price) => {
    //variations[i].price = price;
    //var dummy = [];
    //variations.map((x)=>{
    //  if(x.id == id)
    //});

    // var dummy = variations;
    // dummy[dummy.findIndex(el => el.id == id)].price = price;
    // setVariations(dummy);

    variations[i].price = price;
    setVariations((variations) => [...variations]);

  };

  const onChangeVariationEnable = async (i, enable) => {
    variations[i].enable = enable;
    setVariations((variations) => [...variations]);
  };

  // const loadDetails = async () => {
  //   const { data } = await axiosInstance.get("auth/profile");

  //   if (data.profile) {
  //     const profile = data.profile;
  //     setWebsiteUrl(profile.websiteUrl ?? "");
  //     setDownloadPageUrl(profile.downloadPageUrl ?? "");
  //   }

  //   console.log("details data - " + JSON.stringify(data));
  // };

  const loadDetails = async (userId) => {
    const { data } = await axiosInstance.get("admin/reseller/" + userId);

    if (data.status) {
      setReseller(data.reseller);

      setWebsiteUrl(data.reseller.websiteUrl ?? "");
      setDownloadPageUrl(data.reseller.downloadPageUrl ?? "");

      //setKeys(data.reseller.keys);
      document.getElementById("nav_title").innerHTML =
        (data.reseller.name ? data.reseller.name : data.reseller.email) +
        "'s Setting";
    }
  };

  const onProductSettingEdit = (product) => {
    setActiveProduct(product);
    setProductName(product.name);
    setProductEnable(product.enable);
    setProductSettingModal(true);
    //console.log(JSON.stringify(product));

    var vDummy = products
    .find((x) => x.id == product.id)
    .variations.map((x) => {

      var variation = product.variations.find((v) => v._id === x._id);

      if (variation) {
        return { ...x, id:x._id , point: x.price, price: variation.price, enable: variation.enable };
      } else {
        return { ...x, id:x._id, point: x.price, price: x.price, enable: x.enable };
      }

    });

    console.log("product variations - "+JSON.stringify(product.variations))
    

    setVariations(vDummy);
  };

  const onProductSettingSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (productName === "") {
      error = true;
      errorObj.name = "Product name is required!";
    }

    setErrors(errorObj);

    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "admin/save-product-setting",
          {
            product_id: activeProduct.id,
            name: productName,
            enable: productEnable,
            variations: variations,
            reseller_id: match.params.id,
          }
        );

        if (data.status === false) {
          swal("Save Product Setting", data.message, "error");
        } else {
          setProductSettingModal(false);
          swal("Save Product Setting", data.message, "success");

          setSelectedProduct(null);
          setProductName("");
          setProductEnable(true);

          setTimeout(() => {
            loadProductSetting();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const onSaveStoreSetting = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (websiteUrl === "") {
      errorObj.websiteUrl = "Website Url is Required";
      error = true;
    }

    if (downloadPageUrl === "") {
      errorObj.downloadPageUrl = "Download Page Url is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }

    const formData = {
      website_url: websiteUrl,
      download_page_url: downloadPageUrl,
      reseller_id: match.params.id,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "admin/save-store-setting",
      formData
    );

    if (data.status) {
      swal("Update Store Setting", data.message, "success");
    } else {
      swal("Update Store Setting", data.message, "error");
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={productSettingModal}
        onHide={setProductSettingModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onProductSettingSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Custom Product Name</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setProductSettingModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Enable</label>

                      <Form.Check
                        type="checkbox"
                        label={``}
                        //value={product.enable}
                        checked={productEnable}
                        onClick={(e) => setProductEnable(e.target.checked)}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Product Name
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        autocomplete="off"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        placeholder="Enter Name"
                      />
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>

                    <ListGroup variant="flush">
                      {variations.map((pv,i) => {
                        return (
                          <ListGroup.Item key={pv.id}>
                            <div class="row">
                              <div class="col-4">{pv.title} </div>
                              <div class="col-4">
                                <Button variant="success" size="sm">
                                  {pv.point} Point
                                </Button>
                              </div>

                              <div class="col-4">
                                <Form.Control
                                  width={100}
                                  //style={{ maxWidth: "100px" }}
                                  className="main"
                                  type="number"
                                  placeholder="Point"
                                  value={pv.price}
                                  onChange={(e) =>
                                    onChangeVariationPrice(i, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setProductSettingModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div id="">
                  <div className="pt-3">
                    <div className="settings-form">
                      <h4 className="text-primary">Store Setting</h4>

                      <form onSubmit={onSaveStoreSetting}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Website Url</label>
                            <input
                              type="url"
                              value={websiteUrl}
                              onChange={(e) => setWebsiteUrl(e.target.value)}
                              placeholder="https://"
                              className="form-control"
                            />
                            {errors.websiteUrl && (
                              <div className="text-danger fs-12">
                                {errors.websiteUrl}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label>Download Page Url</label>
                            <input
                              type="url"
                              value={downloadPageUrl}
                              onChange={(e) =>
                                setDownloadPageUrl(e.target.value)
                              }
                              placeholder="https://"
                              className="form-control"
                            />
                            {errors.downloadPageUrl && (
                              <div className="text-danger fs-12">
                                {errors.downloadPageUrl}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            className="btn btn-sm btn-primary"
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                      </form>

                      <h4 className="text-primary">Product Setting</h4>

                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>SR NO.</strong>
                            </th>
                            <th>
                              <strong>Product Name</strong>
                            </th>
                            <th>
                              <strong>Your Product Name</strong>
                            </th>

                            <th>
                              <strong>Enable</strong>
                            </th>

                            <th>
                              <strong>Action</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData.map((product, i) => (
                            <tr key={i}>
                              <td>
                                <strong>{i + 1}</strong>
                              </td>

                              <td>{product.original}</td>
                              <td>
                                <strong>{product.name}</strong>
                              </td>

                              <td>
                                {product.enable ? (
                                  <Badge variant="success light">Yes</Badge>
                                ) : (
                                  <Badge variant="danger light">No</Badge>
                                )}
                              </td>

                              <td>
                                <Button
                                  className="btn btn-sm btn-primary"
                                  onClick={(e) => onProductSettingEdit(product)}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ProductSetting;
