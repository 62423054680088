import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

//const Orders = () => {
const Vouchers = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [xlsData, setXlsData] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const sort = 500;
  let pagination = Array(Math.ceil(vouchers.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [filter, setFilter] = useState("all");
  const [product, setProduct] = useState("all");
  const [products, setProducts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const [keyQty, setKeyQty] = useState(1);
  const [voucherQty, setVoucherQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [errors, setErrors] = useState({});


  const [resellers, setResellers] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [selectedResellerId, setSelectedResellerId] = useState("");

  const [resellerModal, setResellerModal] = useState(false);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadVouchers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    loadProducts();
    loadCampaigns();
  }, [dispatch]);

  useEffect(() => {
    loadVouchers();
    setFilter(match.params.filter);
  }, [match]);

  useEffect(() => {
    if (vouchers) {
      setLoading(false);
      setTableData(vouchers.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [vouchers]);

  const loadVouchers = async () => {
    const { data } = await axiosInstance.post("admin/vouchers", {
      filter: match.params.filter,
      product_id: product,
      search:search
    });

    if (data.status) {
      setVouchers(data.vouchers);
    }
  };

  const loadProducts = async () => {
    const { data } = await axiosInstance.get("products");

    if (data.status) {
      setProducts(data.products);
      if(data.products.length > 0){
        setSelectedProduct(data.products[0].id)
      }
    }
  };

  const loadCampaigns = async () => {
    const { data } = await axiosInstance.post("license/campaigns",{
      search:""
    });

    if (data.status) {
      setCampaigns(data.campaigns);
      if(data.campaigns.length > 0){
        setSelectedCampaign(data.campaigns._id)
        console.log("campaign - "+JSON.stringify(data.campaigns[0]))
        console.log("campaign id - "+data.campaigns[0]._id)
      }
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(vouchers.slice(i * sort, (i + 1) * sort));
  };

  const onDeactive = async (code) => {};

  const initXlsData = async () => {
    var dummy = [];
    vouchers.map((data, i) => {
      dummy.push({
        sr_no: i + 1,
        id: data.id,
        code: data.code,
        keys:data.keys,
        valid:data.valid,
        used:data.used?"Yes":"No",
        campaign_name:data.campaignId?data.campaignId.name:"",
        reseller_id:data.resellerId?data.resellerId.id:"",
        reseller_name:data.resellerId?data.resellerId.name:"",

        redeem_at: data.redeemAt
          ? format(new Date(data.redeemAt), "dd/MM/yyyy H:mma")
          : "",
        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const onGenerate = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (newCampaign === false && selectedCampaign == "") {
      error = true;
      errorObj.campaign = "Campaign is Required";
    } else if (newCampaign && campaignName == "") {
      error = true;
      errorObj.campaign = "Campaign Name is Required";
    }

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (keyQty === "") {
      error = true;
      errorObj.keyQty = "Total keys is Required";
    }

    if (voucherQty === "") {
      error = true;
      errorObj.voucherQty = "Total vouchers is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Key validity is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/create-voucher", {
          new_campaign: newCampaign,
          campaign_name: campaignName,
          campaign_id: selectedCampaign,
          product_id: selectedProduct,
          qty: Number(voucherQty),
          keys: Number(keyQty),
          valid: Number(valid),
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Generate voucher", data.message, "error");
        } else {
          setGenerateKeyModal(false);
          //toast.success(data.message);
          swal("Generate voucher", data.message, "success");

          setKeyQty("1");
          setVoucherQty("1")
          setValid("365");
          setCampaignName("");

          setTimeout(() => {
            loadVouchers();
          }, 500);
        }
      } catch (error) {}
    }
  };


  return (
    <>
      <Modal
        className="modal fade"
        show={generateKeyModal}
        onHide={setGenerateKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGenerate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Generate Vouchers</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGenerateKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">

                  <div className="form-group mb-3">
                      <label className="text-black font-w500">New Campaign</label>
                      <div className="contact-name">
                        <select 
                        className="form-control"
                        value={newCampaign?"Yes":"No"}
                        onChange={(e) => setNewCampaign(e.target.value == "Yes")}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>

                       
                      </div>
                    </div>


                    {newCampaign ? <div className="form-group mb-3">
                      <label className="text-black font-w500">Campaign Name</label>
                      <div className="contact-name">
                      <input
                          type="text"
                          value={campaignName}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setCampaignName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.campaign && (
                          <div className="text-danger fs-12">{errors.campaign}</div>
                        )}

                       
                      </div>
                    </div>:
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">New Campaign</label>
                      <div className="contact-name">
                        {campaigns.length > 0 ? <select 
                        className="form-control"
                        value={selectedCampaign}
                        onChange={(e) => setSelectedCampaign(e.target.value)}
                        >
                          <option value="">Choose Campaign</option>
                          {campaigns.map((c,i)=>{
                            return <option key={i} value={c._id}>{i+1} - {c.name}</option>;
                          })}
                          
                          
                        </select>:<p>No Campaign Found</p>}
                        {errors.campaign && (
                          <div className="text-danger fs-12">{errors.campaign}</div>
                        )}
                       
                      </div>
                    </div>}

                    <div className="form-group mb-3">
                      <label className="text-black font-w500"> Products</label>
                      <div className="contact-name">
                        <select 
                        className="form-control"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        >
<option value="">Choose Product</option>
                          {products.map((p,i)=>{
                            return <option key={i} value={p.id}>{i+1} - {p.name}</option>;
                          })}
                          
                          
                        </select>
                        {errors.product && (
                          <div className="text-danger fs-12">{errors.product}</div>
                        )}
                       
                      </div>
                    </div>


                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Vouchers</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={voucherQty}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setVoucherQty(e.target.value)}
                          placeholder="Total Vouchers"
                        />
                        {errors.voucherQty && (
                          <div className="text-danger fs-12">{errors.voucherQty}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Keys</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={keyQty}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setKeyQty(e.target.value)}
                          placeholder="Total Keys"
                        />
                        {errors.keyQty && (
                          <div className="text-danger fs-12">{errors.keyQty}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Validity</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setValid(e.target.value)}
                          placeholder="Validity in days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">{errors.valid}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>



                  
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGenerateKeyModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Generate
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Voucher Codes</h4>

              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadCampaigns();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <Button className="btn btn-primary font-w600 mr-2" onClick={()=> setGenerateKeyModal(true)}>Generate Vouchers</Button>


              <CSVLink
                data={xlsData}
                filename={"vouchers-" + match.params.filter + ".csv"}
                className="btn btn-primary"
                target="_blank"
                asyncOnClick={true}
                onClick={async (event, done) => {
                  console.log("You click the link");
                  await initXlsData();
                  done(); // 👍🏻 You are stopping the handling of component
                }}
              >
                Export
              </CSVLink>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : vouchers.length == 0 ? (
                <p>No Voucher Codes Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Code</th>
                          <th>Reseller</th>
                          <th>Product</th>
                          <th>Campaign</th>
                          <th>Keys</th>
                          <th>Valid</th>
                          <th>Redeem</th>
                          <th>Redeem At</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{whiteSpace:"nowrap"}} onClick={() => {navigator.clipboard.writeText(d.code)}}>{d.code}</td>
                          
                            {d.resellerId ? <td>
                              <Link to={"/reseller/"+d.resellerId.id}>{d.resellerId.name}</Link>
                              <br />
                              <small>{d.resellerId.phone}</small>
                              <br />
                              <small>{d.resellerId.email}</small>
      
                            </td>:
                            
                            <td>No</td>}

                            <td>{productName(d.productId)}</td>

                            <td>{d.campaignId ? d.campaignId.name : ""}</td>

                            <td>{d.keys} Keys</td>
                            <td>{d.valid} Days</td>
                            <td>{d.used?"Yes":"No"}</td>

                            <td>
                              {d.redeemAt
                                ? format(
                                    new Date(d.redeemAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              {d.createdAt
                                ? format(
                                    new Date(d.createdAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                 
                      

                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                        <th>Sr No</th>
                          <th>Code</th>
                          <th>Reseller</th>
                          <th>Product</th>
                          <th>Campaign</th>
                          <th>Keys</th>
                          <th>Valid</th>
                         
                          <th>Redeem</th>
                          <th>Redeem At</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to &nbsp;
                        {vouchers.length < (activePage + 1) * sort
                          ? vouchers.length
                          : (activePage + 1) * sort}
                        &nbsp;of {vouchers.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Vouchers;
