import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
  Row,
  Col,
  Form,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import moment from "moment";

import DTable from "../components/Table";

import Select from "react-select";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//const Orders = () => {
const Addons = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [resellerModal, setResellerModal] = useState(false);

  const [productAddons, setProductAddons] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [generateAddonModal, setGenerateAddonModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedProductAddon, setSelectedProductAddon] = useState("");

  const [note, setNote] = useState("");

  const [count, setCount] = useState(1);
  const [valid, setValid] = useState(365);
  const [errors, setErrors] = useState({});

  const [resellers, setResellers] = useState([]);
  const [activeAddon, setActiveAddon] = useState(null);
  const [selectedResellerId, setSelectedResellerId] = useState(null);

  const [editAddonModal, setEditAddonModal] = useState(false);

 
  const [enable, setEnable] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const [status, setStatus] = useState("0");

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  //instance
  const [instanceModal, setInstanceModal] = useState(false);
  const [instance, setInstance] = useState(10);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadAddons();
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadAddons();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    loadProductAddons();
    loadCampaigns();
    loadResellers();
  }, [dispatch]);

  useEffect(() => {
    loadAddons();
    setFilter(match.params.filter);
  }, [match]);


  const loadAddons = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("addon/all", {
      filter: match.params.filter,
      addon_id: selectedProductAddon,
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setAddons(data.addons);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
    }
  };

  const loadProductAddons = async () => {
    const { data } = await axiosInstance.get("addon");

    if (data.status) {
      setProductAddons(data.productAddons);
      if (data.productAddons.length > 0) {
        setSelectedProductAddon(data.productAddons[0].id);
      }
    }
  };

  const loadCampaigns = async () => {
    const { data } = await axiosInstance.post("license/campaigns", {
      search: "",
    });

    if (data.status) {
      setCampaigns(data.campaigns);
      if (data.campaigns.length > 0) {
        setSelectedCampaign(data.campaigns._id);
      }
    }
  };

  const onChangeAddonStatus = async (addon, status) => {
    const { data } = await axiosInstance.post("addon/change-status", {
      addon_id: addon.id,
      status: status,
    });

    if (data.status === false) {
      swal("Change addon Status", data.message, "error");
    } else {
      swal("Change addon Status", data.message, "success");
      // setTimeout(() => {
      //   loadAddons();
      // }, 500);

      var dummy = [...addons];

      const index = dummy.findIndex((x) => x.id === addon.id);

      dummy[index].enable = status;
      setAddons(dummy);
    }
  };

  function productAddonName(id) {
    if (id) {
      const productIndex = productAddons.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return productAddons[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const onGenerate = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (newCampaign === false && selectedCampaign == "") {
      error = true;
      errorObj.campaign = "Campaign is Required";
    } else if (newCampaign && campaignName == "") {
      error = true;
      errorObj.campaign = "Campaign Name is Required";
    }

    if (selectedProductAddon === "") {
      error = true;
      errorObj.productAddon = "Product Addon is Required";
    }

    if (count === "") {
      error = true;
      errorObj.addons = "Count is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Addon validity is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("addon/generate", {
          new_campaign: newCampaign,
          campaign_name: campaignName,
          campaign_id: selectedCampaign,
          addon_id: selectedProductAddon,
          count: Number(count),
          valid: Number(valid),
          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Generate addon", data.message, "error");
        } else {
          setGenerateAddonModal(false);
          //toast.success(data.message);
          swal("Generate addon", data.message, "success");

          setCount("1");
          setValid("365");
          setCampaignName("");
          setNote("");

          setTimeout(() => {
            loadAddons();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const removeResellerFromAddon = async (addon) => {
    const { data } = await axiosInstance.post("admin/remove-addon-reseller", {
      addon_id: addon.id,
    });

    if (data.status) {
      swal("Remove Reseller", data.message, "success");
     
      var dummy = [...addons];
      const index = dummy.findIndex((x) => x.id === addon.id);
     
      if (index != -1) {
        dummy[index].resellerId = null;
        delete dummy[index].resellerId;
      }
      setAddons(dummy);
    } else {
      swal("Remove Reseller", data.message, "error");
    }
  };

  const handleAssignReseller = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (!selectedResellerId) {
      error = true;
      errorObj.reseller = "Reseller is Required";
    }

    setErrors(errorObj);

    if (!error) {
      const { data } = await axiosInstance.post("admin/change-addon-reseller", {
        addon_id: activeAddon.id,
        reseller_id: selectedResellerId.value,
      });

      if (data.status) {
        swal("Assign To Reseller", data.message, "success");
        setResellerModal(false);

        var dummy = [...addons];

        const index = dummy.findIndex((x) => x.id === activeAddon.id);
        const reseller = resellers.find(
          (reseller) => reseller._id === selectedResellerId.value
        );

        if (reseller) {
          dummy[index].resellerId = reseller;
        }

        setAddons(dummy);
      } else {
        swal("Assign To Reseller", data.message, "error");
      }
    }
  };

  const assignReseller = async (addon) => {
    loadResellers();
    setActiveAddon(addon);
    setResellerModal(true);

    if (addon.resellerId) {
      setSelectedResellerId({
        value: addon.resellerId._id,
        label: addon.resellerId.name + "(" + addon.resellerId.phone + ")",
      });
    } else {
      setSelectedResellerId(null);
    }
  };

  const loadResellers = async () => {
    const { data } = await axiosInstance.post("admin/resellers", {
      type: "all",
    });

    if (data.status) {
      setResellers(data.resellers);
    }
  };

  const onEditAddon = async (addon) => {
    setActiveAddon(addon);
  
    setActiveDate(addon.activateAt ?? "");
    setExpireDate(addon.expireAt ?? "");
    setValid(addon.valid);
    setEnable(addon.enable);
    setStatus(addon.status);
    setSelectedProductAddon(addon.addonId);
    setSelectedCampaign(addon.campaignId);
    setNote(addon.note ?? "");
    setEditAddonModal(true);
  };


  const onUpdateAddonDetails = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedProductAddon === "") {
      error = true;
      errorObj.productAddon = "Product Addon is Required";
    }

    if (status != "0" && activeDate == "") {
      error = true;
      errorObj.activeDate = "Activate Date is Required";
    }

    if (status != "0" && expireDate == "") {
      error = true;
      errorObj.expireAt = "Expire Date is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("addon/update-details", {
          addon_id: activeAddon.id,
          campaign_id: selectedCampaign,
          addon_id: selectedProductAddon,
          enable: enable,
          status: Number(status),
          valid: Number(valid),

          activate_at: activeDate,
          expire_at: expireDate,

          note: note,
        });

        if (data.status === false) {
          
          swal("Update addon", data.message, "error");
        } else {
          setEditAddonModal(false);
          swal("Update addon", data.message, "success");

          setCount("1");
          setValid("365");
          setCampaignName("");
          setNote("");
        

          var dummy = [...addons];
          const index = dummy.findIndex((x) => x.id === activeAddon.id);
          dummy[index].enable = enable;
          dummy[index].status = status;
       
          dummy[index].valid = valid;
          dummy[index].note = note;
          setAddons(dummy);

          if (status == "0") {
            dummy[index].activateAt = null;
            dummy[index].expireAt = null;
          } else {
            dummy[index].activateAt = activeDate;
            dummy[index].expireAt = expireDate;
          }
        }
      } catch (error) {}
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactivate</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Code",
      accessor: "code",
    
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.code);
            }}
          >
            {d.code}
          </span>
        );
        
      },
    },
    {
      Header: "License",
      accessor: "licenseId",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return d.licenseId? d.licenseId : "";
      },
    },
    {
      Header: "Reseller",
      accessor: "resellerId",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return d.resellerId ? (
          <div>
            <Link to={"/reseller/" + d.resellerId.id}>{d.resellerId.name}</Link>
            <br />
            <small>{d.resellerId.phone}</small>
            <br />
            <small>{d.resellerId.email}</small>
          </div>
        ) : (
          <div>No</div>
        );
      },
    },

    {
      Header: "Addon",
      accessor: "addonId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return productAddonName(d.addonId);
      },
    },

    {
      Header: "Campaign",
      accessor: "campaignId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.campaignId ? d.campaignId.name : "";
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {d.enable ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeAddonStatus(d, false)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeAddonStatus(d, true)}
                >
                  Enable
                </Dropdown.Item>
              )}

              <Dropdown.Item as="button" onClick={(e) => assignReseller(d)}>
                {d.resellerId ? "Change Reseller" : "Assign to Reseller"}
              </Dropdown.Item>

              {d.resellerId ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => removeResellerFromAddon(d)}
                >
                  Remove Reseller
                </Dropdown.Item>
              ) : (
                <></>
              )}

              <Dropdown.Item as="button" onClick={(e) => onEditAddon(d)}>
                Edit
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];



  return (
    <>
    

      <Modal
        size={"xl"}
        className="modal fade"
        show={editAddonModal}
        onHide={setEditAddonModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onUpdateAddonDetails}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  Addon Details -{" "}
                  {activeAddon ? (
                    <small className="b">{activeAddon.code}</small>
                  ) : (
                    ""
                  )}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditAddonModal(false)}
                >
                  <span>×</span>
                </button>
              </div>

              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label
                            id=""
                            column
                            md="4"
                            className="required-field"
                          >
                            Is Enable
                          </Form.Label>
                          <Col md="7">
                            <Form.Check
                              type="checkbox"
                              id={`isActive`}
                              label={``}
                              value={enable}
                              checked={enable}
                              onClick={(e) => setEnable(!enable)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6"></Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Campaign:
                          </Form.Label>

                          <Col md="7">
                            {campaigns.length > 0 ? (
                              <select
                                className="form-control"
                                value={selectedCampaign}
                                onChange={(e) =>
                                  setSelectedCampaign(e.target.value)
                                }
                              >
                                <option value="">Choose Campaign</option>
                                {campaigns.map((c, i) => {
                                  return (
                                    <option key={i} value={c._id}>
                                      {i + 1} - {c.name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <p>No Campaign Found</p>
                            )}
                            {errors.campaign && (
                              <div className="text-danger fs-12">
                                {errors.campaign}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Product Addon:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={selectedProductAddon}
                              onChange={(e) =>
                                setSelectedProductAddon(e.target.value)
                              }
                            >
                              <option value="">Choose Product Addon</option>
                              {productAddons.map((p, i) => {
                                return (
                                  <option key={i} value={p.id}>
                                    {i + 1} - {p.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.productAddon && (
                              <div className="text-danger fs-12">
                                {errors.productAddon}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <h4 className="h4 text-dark mt-4">Addon Details</h4>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Valid:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="number"
                              value={valid}
                              placeholder="Enter Days"
                              className="form-control"
                              required="true"
                              min={0}
                              max={36500}
                              onChange={(e) => {
                                setValid(e.target.value);

                                var currentDate = activeDate; //Date.now();
                                var ed = moment(currentDate);
                                ed.add(e.target.value, "days").format(
                                  "YYYY-MM-DD hh:mm"
                                );

                                setExpireDate(ed);
                              }}
                            />{" "}
                            {errors.valid && (
                              <div className="text-danger fs-12">
                                {errors.valid}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Status:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="0">Available</option>
                              <option value="1">Active</option>
                              {/*  <option value="2">Deactive</option> */}
                              <option value="3">Expire</option>
                            </select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    {status != "0" ? (
                      <Row>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Activation Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Activation Date"
                                  value={activeDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setActiveDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate = moment(date).startOf("day");
                                    var endDate =
                                      moment(expireDate).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />

                             
                              </MuiPickersUtilsProvider>

                              {errors.activeDate && (
                                <div className="text-danger fs-12">
                                  {errors.activeDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Expire Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Expire Date"
                                  value={expireDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setExpireDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate =
                                      moment(activeDate).startOf("day");
                                    var endDate = moment(date).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />
                              </MuiPickersUtilsProvider>

                            

                              {errors.expireDate && (
                                <div className="text-danger fs-12">
                                  {errors.expireDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}

                    <h4 className="h4 text-dark mt-4">User Details</h4>

                  

                    <Row>
                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Note :
                          </Form.Label>
                          <Col md="7">
                            <textarea
                              rows={3}
                              placeholder="Enter Note"
                              className="form-control"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditAddonModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"> </i>
                  Close
                </button>

                <button type="submit" className="btn btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={generateAddonModal}
        onHide={setGenerateAddonModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGenerate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Generate Addons</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGenerateAddonModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        New Campaign
                      </label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={newCampaign ? "Yes" : "No"}
                          onChange={(e) =>
                            setNewCampaign(e.target.value == "Yes")
                          }
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    {newCampaign ? (
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Campaign Name
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={campaignName}
                            className="form-control"
                            autocomplete="off"
                            name="name"
                            required="required"
                            onChange={(e) => setCampaignName(e.target.value)}
                            placeholder="Name"
                          />
                          {errors.campaign && (
                            <div className="text-danger fs-12">
                              {errors.campaign}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          New Campaign
                        </label>
                        <div className="contact-name">
                          {campaigns.length > 0 ? (
                            <select
                              className="form-control"
                              value={selectedCampaign}
                              onChange={(e) =>
                                setSelectedCampaign(e.target.value)
                              }
                            >
                              <option value="">Choose Campaign</option>
                              {campaigns.map((c, i) => {
                                return (
                                  <option key={i} value={c._id}>
                                    {i + 1} - {c.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <p>No Campaign Found</p>
                          )}
                          {errors.campaign && (
                            <div className="text-danger fs-12">
                              {errors.campaign}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label className="text-black font-w500"> Product Addon</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={selectedProductAddon}
                          onChange={(e) => setSelectedProductAddon(e.target.value)}
                        >
                          <option value="">Choose Product Addon</option>
                          {productAddons.map((p, i) => {
                            return (
                              <option key={i} value={p.id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.productAddon && (
                          <div className="text-danger fs-12">
                            {errors.productAddon}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Addons</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={count}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setCount(e.target.value)}
                          placeholder="Total Addons"
                        />
                        {errors.count && (
                          <div className="text-danger fs-12">{errors.count}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Validity</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setValid(e.target.value)}
                          placeholder="Validity in days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Note</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          value={note}
                          rows={4}
                          className="form-control"
                          autocomplete="off"
                          required="required"
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Note"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGenerateAddonModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Generate
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={resellerModal}
        onHide={setResellerModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleAssignReseller}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Assign Reseller To Addon</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setResellerModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Assign reseller to below addon{" "}
                      <b>
                        <pre>{activeAddon ? activeAddon.code : ""}</pre>
                      </b>
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Reseller</label>
                      <div className="contact-name">
                       

                        {resellers.length > 0 ? (
                          <Select
                            //className="form-control"
                            defaultValue={selectedResellerId}
                            value={selectedResellerId}
                            onChange={(option) => setSelectedResellerId(option)}
                            // onChange={(option) =>
                            //   setSelectedResellerId(option.value)
                            // }
                            options={[
                              {
                                value: "",
                                label: "Choose Reseller",
                              },
                              ...resellers.map((user, i) => {
                                return {
                                  value: user._id,
                                  label: user.name + "(" + user.phone + ")",
                                };
                              }),
                            ]}
                          >
                            {/* <option value="">Choose Reseller</option>
                            {resellers.map((c, i) => {
                              return (
                                <option key={i} value={c._id}>
                                  {i + 1} - {c.name}{" "}({c._id})
                                </option>
                              );
                            })} */}
                          </Select>
                        ) : (
                          <p>No Reseller Found</p>
                        )}
                        {errors.reseller && (
                          <div className="text-danger fs-12">
                            {errors.reseller}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setResellerModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Addons</h4>

              <div class="row">
                <div className="col-md-6 p-2">
                  <div className="input-group search-area d-lg-inline-flex mr-2">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (searchTimeout) {
                          clearTimeout(searchTimeout);
                        }
                        
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </form>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <Button
                    className="btn btn-primary font-w600 mr-2"
                    onClick={() => setGenerateAddonModal(true)}
                  >
                    Generate Addons
                  </Button>

                  {/* <CSVLink
                    data={xlsData}
                    filename={"addons-" + match.params.filter + ".csv"}
                    className="btn btn-primary"
                    target="_blank"
                    asyncOnClick={true}
                    onClick={async (event, done) => {
                      console.log("You click the link");
                      await initXlsData();
                      done(); // 👍🏻 You are stopping the handling of component
                    }}
                  >
                    Export
                  </CSVLink> */}
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : addons.length == 0 ? (
                <p>No Addons Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={addons}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />

                
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Addons;
