import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  //Table,
  //Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import DTable from "../components/Table";

import { useLocation } from "react-router-dom";
import Select from "react-select";

//dummy data
//import { records as data, expandableRecords } from './data';

//const Orders = () => {
const Resellers = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [allResellers, setAllResellers] = useState([]);
  const [redirectModal, setRedirectModal] = useState(false);
  const [resellers, setRellers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const sort = 10;

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [activeReseller, setActiveReseller] = useState(null);

  const [selectedResellerId, setSelectedResellerId] = useState(null);

  const [addMemberModal, setAddMemberModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [rebrand, setRebrand] = useState(false);
  const [rebrandProductList, setRebrandProductList] = useState([]);
  let errorsObj = { name: "", email: "", phone: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [editMemberModal, setEditMemberModal] = useState(false);

  const [products, setProducts] = useState([]);
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const [keyQty, setKeyQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [note, setNote] = useState("");

  //const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const [transferModal, setTransferModal] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0);

  const [deductModal, setDeductModal] = useState(false);
  const [walletNote, setWalletNote] = useState("");

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadResellers();
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadResellers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    loadProducts();
  }, [dispatch]);

  useEffect(() => {
    //setPage(0)
    //setPageSize(10)
    //setMaxPage(1)
    loadResellers();
    loadAllResellers();
  }, [match]);

  const loadAllResellers = async () => {
    const { data } = await axiosInstance.post("admin/resellers",{type:"all"});
    if (data.status) {
      setAllResellers(data.resellers);
    }
  };

  const loadProducts = async () => {
    const { data } = await axiosInstance.get("products");

    if (data.status) {
      setProducts(data.products);
      if (data.products.length > 0) {
        setSelectedProduct(data.products[0].id);
      }
    }
  };

  function onCheckRebrandProduct(id,value) {

    console.log("onCheckRebrandProduct - "+id+", value - "+value)

    if(rebrandProductList.includes(id) && !value){
      setRebrandProductList(rebrandProductList.filter((x)=> x != id));
    }else{
      setRebrandProductList([...rebrandProductList,id])
    }

  }

  useEffect(() => {
    if (resellers) {
      //setLoading(false);
      setTableData(resellers.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [resellers]);

  const loadResellers = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("admin/resellers", {
      search: search,
      page: page,
      limit: pageSize,
      type: match.params?.type == "master" ? "master-reseller" : "reseller",
    });

    if (data.status) {
      setRellers(data.resellers);
      //setTotal(data.total);

      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
    }
    setLoading(false);
  };

  const initXlsData = async () => {
    const { data } = await axiosInstance.post("admin/resellers", {
      search: search,
      page: 0,
      limit: maxPage * pageSize,
    });

    if (data.status) {
      console.log("total resellers - " + data.resellers.length);

      var dummy = [];
      data.resellers.map((d, i) => {
        var address = "";
        if (d.address) {
          if (d.address.addressLine1) {
            address =
              (d.address.addressLine1 ?? "") +
              ", " +
              (d.address.addressLine2 ?? "") +
              ", " +
              (d.address.city ?? "") +
              ", " +
              (d.address.state ?? "") +
              ", " +
              (d.address.country ?? "") +
              " - " +
              (d.address.zip ?? "");
          } else {
            address = "Not Available";
          }
        }

        dummy.push({
          sr_no: i + 1,
          id: d.id,
          name: d.name,
          email: d.email,
          phone: d.phone,
          level: d.agentLevel,
          address: address,
          counter: d.counter,
          keys: d.keys,

          joined: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
          status: d.status === "active" ? "Activated" : "Deactivated",
        });
      });

      setXlsData(dummy);
    }
  };

  const changeAgentStatus = async (agentId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: agentId,
      status: status,
      sub_agent: false,
    });

    if (data.status) {
      swal("Reseller", data.message, "success");

      var dummy = [...resellers];

      const agentIndex = dummy.findIndex((x) => x.id === agentId);

      dummy[agentIndex].status = status;
      console.log("order index - " + agentIndex);

      setRellers(dummy);
      onClick(activePage);
    } else {
      swal("Reseller", data.message, "error");
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(resellers.slice(i * sort, (i + 1) * sort));
  };

  const onAllotKeys = async (reseller) => {
    setActiveReseller(reseller);
    setGenerateKeyModal(true);
  };

  const onProductSetting = async (reseller) => {
    //setActiveReseller(reseller);
    history.push("/product-setting/" + reseller.id);
  };

  const onGenerate = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (keyQty === "") {
      error = true;
      errorObj.keys = "Keys is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Key validity is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("license/allot-to-reseller", {
          reseller_id: activeReseller._id,
          product_id: selectedProduct,
          keys: Number(keyQty),
          valid: Number(valid),
          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Allot license", data.message, "error");
        } else {
          setGenerateKeyModal(false);
          //toast.success(data.message);
          swal("Allot license", data.message, "success");

          setKeyQty("1");
          setValid("365");
          setNote("");

          setTimeout(() => {
            loadResellers();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const onAddMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone Number is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/add-reseller", {
          name: name,
          phone: phone,
          email: email,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Add User", data.message, "error");
        } else {
          setAddMemberModal(false);
          //toast.success(data.message);
          swal("Add User", data.message, "success");

          setPhone("");
          setEmail("");
          setName("");

          setTimeout(() => {
            loadResellers();
          }, 500);
        }
      } catch (error) {}
    }
  };

  

  const redirectReseller = async (d) => {
    
    setActiveReseller(d);
    setRedirectModal(true);
    loadAllResellers();

    if(d.redirectTo){
      setSelectedResellerId( {value: d.redirectTo._id,
        label: d.redirectTo.name + "(" + d.redirectTo.phone + ")",})
    }else{
      setSelectedResellerId({
        value: "",
        label: "No Redirect",
      })
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactive</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  const getStatus = (status) => {
    return status === "active" ? (
      <Badge variant="success light">
        Activated
        <span className="ml-1 fa fa-check"></span>
      </Badge>
    ) : status === "deactive" ? (
      <Badge variant="danger light">
        Deactivated
        <span className="ml-1 fa fa-ban"></span>
      </Badge>
    ) : (
      <></>
    );
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: true,
      accessor: "",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Link to={(match.params?.type == "master" ? "/master":"/reseller") + "/" + d._id}>
            {Number(row.id) + page * pageSize + 1}
          </Link>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <Link to={(match.params?.type == "master" ? "/master":"/reseller") + "/" + d._id}>{d.name}</Link>;
      },
    },
    {
      Header: "Contact",
      accessor: "",
      sort: false,

      Cell: ({ row }) => {
        const d = row.original;

        return (
          <div>
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
          </div>
        );

        //return <Link to={"/reseller/" + d._id}>{d.phone}</Link>;
      },
    },
    // {
    //   Header: "Email",
    //   accessor: "email",
    //   sort: false,
    // },

    {
      Header: "Master",
      accessor: "master",
      Cell: ({ row }) => {
        const d = row.original;
        return d.masterResellerId ? (
          <div>
            <Link to={"/reseller/" + d.masterResellerId.id}>
              {d.masterResellerId.name}
            </Link>
            <br />
            <small>{d.masterResellerId.phone}</small>
            <br />
            <small>{d.masterResellerId.email}</small>
          </div>
        ) : (
          <div>No</div>
        );
      },
    },

    {
      Header: "Badge",
      accessor: "badge",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <>
            {(d.badge??[]).map((x) => {
              return <Badge variant="primary light">{x}</Badge>;
            })}
          </>
        );
      },
    },

    {
      Header: "Wallet",
      accessor: "wallet",
      //sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <b>{d.wallet}</b>;
      },
    },

    {
      Header: "Rebrand",
      accessor: "rebrand",
      Cell: ({ row }) => {
        const d = row.original;
        return d.rebrand ? <b className="text-success">Yes</b> : <b>No</b>;
      },
    },

    // {
    //   Header: "Total Keys",
    //   accessor: "keys",
    //   sort: true,
    // },

    // {
    //   Header: "Available Keys",
    //   accessor: "availableKeys",
    //   sort: true,
    // },

    // {
    //   Header: "Active Keys",
    //   accessor: "activeKeys",
    //   sort: true,
    // },

    // {
    //   Header: "Expire Keys",
    //   accessor: "expireKeys",
    //   sort: true,
    // },

    {
      Header: "Ip",
      accessor: "ip",
      sort: true,
    },

    {
      Header: "Joined",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      //Cell: props => getStatus();
      //Cell: ({ cell: { value } }) => getStatus(value),

      Cell: ({ row }) => {
        const data = row.original;
        //return <p>{data._id}</p>
        return getStatus(data.status);
      },

      //custom:true,
      //getUI:getStatus
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={(e) => onEditMember(d)}>
                Edit Profile
              </Dropdown.Item>
              {/* <div className="dropdown-divider"></div> */}

              <Dropdown.Item as="button" onClick={(e) => onAllotKeys(d)}>
                Allot Keys
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) =>
                  changeAgentStatus(
                    d.id,
                    d.status === "active" ? "deactive" : "active"
                  )
                }
              >
                {d.status === "active" ? "Deactive Now" : "Active Now"}
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={(e) => onProductSetting(d)}>
                Product Setting
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => {
                  setActiveReseller(d);
                  setTransferModal(true);
                  setWalletAmount(0)
                }}
              >
                Wallet Recharge
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => {
                  setActiveReseller(d);
                  setDeductModal(true);
                  setWalletAmount(0)
                }}
              >
                Deduct Wallet
              </Dropdown.Item>

              <Dropdown.Item
              as="button"
              onClick={(e) => redirectReseller(d)}
            >
              {d.resellerId
                ? "Change Redirect Reseller"
                : "Assign Redirect Reseller"}
            </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  //edit
  function onEditMember(reseller) {
    setActiveReseller(reseller);

    setPhone(reseller.phone);
    setEmail(reseller.email);
    setName(reseller.name);

    setRebrand(reseller.rebrand);
    setRebrandProductList(reseller.rebrandProducts??[])

    setEditMemberModal(true);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    } else if (!validateEmail(email)) {
      error = true;
      errorObj.email = "Enter Valid Email!";
    }

    setErrors(errorObj);
    if (!error) {
      const { data } = await axiosInstance.post("admin/update-reseller", {
        name: name,
        rebrand: rebrand,
        email: email,
        reseller_id: activeReseller._id,
        rebrand_products: rebrandProductList
      });

      if (data.status === false) {
        swal("Update Reseller Details", data.message, "error");
      } else {
        setEditMemberModal(false);
        swal("Update Reseller Details", data.message, "success");

        setTimeout(() => {
          loadResellers();
        }, 500);
      }
    }
  };

  // Update Profile Submit data
  const onWalletRechargeFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const { data } = await axiosInstance.post("admin/wallet-recharge", {
        amount: walletAmount,
        master_reseller_id: activeReseller.id,
      });

      if (data.status === false) {
        //toast.error(data.message);
        swal("Wallet Recharge", data.message, "error");
      } else {
        //toast.success(data.message);
        setTransferModal(false);
        swal("Wallet Recharge", data.message, "success");

        // var dummy = [...resellers];
        // const agentIndex = dummy.findIndex((x) => x.id === activeReseller.id);
        // dummy[agentIndex].wallet = dummy[agentIndex].wallet + walletAmount;
        // setRellers(dummy);

        setTimeout(() => {
          loadResellers();
        }, 500);
      }
    } catch (error) {
      swal("Wallet Recharge", error.message, "error");
      setTransferModal(false);
      //loadResellers();
    }
  };


  const onDeductWalletFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const { data } = await axiosInstance.post("admin/deduct-wallet", {
        amount: walletAmount,
        note:walletNote,
        master_reseller_id: activeReseller.id,
      });

      if (data.status === false) {
        //toast.error(data.message);
        swal("Deduct Wallet", data.message, "error");
      } else {
        //toast.success(data.message);
        setDeductModal(false);
        swal("Deduct Wallet", data.message, "success");

        setTimeout(() => {
          loadResellers();
        }, 500);
      }
    } catch (error) {
      swal("Deduct Wallet", error.message, "error");
      setDeductModal(false);
      //loadResellers();
    }
  };

  const handleRedirectReseller = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (!selectedResellerId) {
      error = true;
      errorObj.reseller = "Reseller is Required";
    }

    setErrors(errorObj);

    if (!error) {
      const { data } = await axiosInstance.post("admin/redirect-reseller", {
        reseller_id: activeReseller._id,
        redirect_id: selectedResellerId.value,
      });

      if (data.status) {
        swal("Redirect Reseller", data.message, "success");
        setRedirectModal(false);

        var dummy = [...resellers];

        const index = resellers.findIndex((x) => x._id === activeReseller._id);
       
        if (index != -1) {
          dummy[index].redirectTo = selectedResellerId.value;
        }

        setRellers(dummy);
     
      } else {
        swal("Redirect Reseller", data.message, "error");
      }
    }
  };

  //create react boostrap modal code


  return (
    <>


<Modal
        className="modal fade"
        show={redirectModal}
        onHide={setRedirectModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleRedirectReseller}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Redirect Reseller To </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setRedirectModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Reseller {" "}
                      <b>
                        <pre>{activeReseller?.name + "("+ activeReseller?.phone +")"}</pre>
                      </b>
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Reseller</label>
                      <div className="contact-name">


                        {allResellers.length > 0 ? (
                          <Select
                            //className="form-control"
                            defaultValue={selectedResellerId}
                            value={selectedResellerId}
                            onChange={(option) => setSelectedResellerId(option)}
                            // onChange={(option) =>
                            //   setSelectedResellerId(option.value)
                            // }
                            options={[{
                              value: "",
                              label: "No Redirect",
                            },...allResellers.map((user, i) => {
                              return {
                                value: user._id,
                                label: user.name + "(" + user.phone + ")",
                              };
                            })]}
                          >
                            {/* <option value="">Choose Reseller</option>
                            {resellers.map((c, i) => {
                              return (
                                <option key={i} value={c._id}>
                                  {i + 1} - {c.name}{" "}({c._id})
                                </option>
                              );
                            })} */}
                          </Select>
                        ) : (
                          <p>No Reseller Found</p>
                        )}
                        {errors.reseller && (
                          <div className="text-danger fs-12">
                            {errors.reseller}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setRedirectModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>



      <Modal
        className="modal fade"
        show={transferModal}
        onHide={setTransferModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Wallet Recharge</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setTransferModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Wallet recharge for{" "}
                      {activeReseller ? activeReseller.name : ""}(
                      {activeReseller ? activeReseller.phone : ""})
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Amount</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={walletAmount}
                          min={0}
                          className="form-control"
                          autocomplete="off"
                          name="Amount"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          onChange={(e) => setWalletAmount(e.target.value)}
                          placeholder="Amount"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setTransferModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onWalletRechargeFormSubmit}
                >
                  Recharge
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>


      <Modal
        className="modal fade"
        show={deductModal}
        onHide={setDeductModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Deduct Wallet</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setDeductModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Deduct Wallet from{" "}
                      {activeReseller ? activeReseller.name : ""}(
                      {activeReseller ? activeReseller.phone : ""})
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Amount</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={walletAmount}
                          min={0}
                          className="form-control"
                          autocomplete="off"
                          name="Amount"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          onChange={(e) => setWalletAmount(e.target.value)}
                          placeholder="Amount"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>


                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Note</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          value={walletNote}
                          rows={4}
                          className="form-control"
                          autocomplete="off"
                          required="required"
                          onChange={(e) => setWalletNote(e.target.value)}
                          placeholder="Note"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setDeductModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onDeductWalletFormSubmit}
                >
                  Deduct
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={addMemberModal}
        onHide={setAddMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Reseller</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        {/* 	<input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="10 Digit Phone Number"
													/> */}

                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          onChange={setPhone}
                        />
                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={setEditMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Reseller Details</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={phone}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          readOnly
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone Number with country code"
                        />
                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group">
                        <label>Rebrand</label>

                        <Form.Check
                            id="rebrand"
                            label="Enable"
                            defaultChecked={rebrand}
                            value={rebrand}
                            onChange={(e) => setRebrand(e.target.checked)}
                          />


                          {rebrand ?<div className="mt-2">
                          {products.map((x,i)=>{
                             return <Form.Check
                             key={"cb-product-"+i}
                             id={"cb-product-"+i}
                             label={x.name}
                             defaultChecked={rebrandProductList.includes(x.id)}
                             value={rebrandProductList.includes(x.id)}
                             onChange={(e) => onCheckRebrandProduct(x.id,e.target.checked)}
                           />
                          })}
                          </div>:<></>}

                        {/* <select
                          className="form-control"
                          value={rebrand ? "yes" : "no"}
                          onChange={(e) => setRebrand(e.target.value == "yes")}
                          defaultValue="yes"
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={generateKeyModal}
        onHide={setGenerateKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGenerate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Allot Keys</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGenerateKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <p>
                        Allot License keys to reseller -{" "}
                        {activeReseller ? activeReseller.name : ""}
                      </p>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500"> Products</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                          <option value="">Choose Product</option>
                          {products.map((p, i) => {
                            return (
                              <option key={i} value={p.id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.product && (
                          <div className="text-danger fs-12">
                            {errors.product}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Keys</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={keyQty}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setKeyQty(e.target.value)}
                          placeholder="Total Keys"
                        />
                        {errors.keys && (
                          <div className="text-danger fs-12">{errors.keys}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Validity</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setValid(e.target.value)}
                          placeholder="Validity in days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Note</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          value={note}
                          rows={4}
                          className="form-control"
                          autocomplete="off"
                          required="required"
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Note"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGenerateKeyModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Allot
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Resellers</h4>

              <div class="row">
                <div className="col-md-6 p-2">
                  <div className="input-group search-area d-lg-inline-flex">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (searchTimeout) {
                          clearTimeout(searchTimeout);
                        }
                        loadResellers();
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </form>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <Button
                    className="btn btn-primary font-w600 mr-2"
                    onClick={() => setAddMemberModal(true)}
                  >
                    Add Reseller
                  </Button>

                  <CSVLink
                    data={xlsData}
                    filename={"resellers.csv"}
                    className="btn btn-primary"
                    target="_blank"
                    asyncOnClick={true}
                    onClick={async (event, done) => {
                      console.log("You click the link");
                      await initXlsData();
                      done(); // 👍🏻 You are stopping the handling of component
                    }}
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>

            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : resellers.length == 0 ? (
                <p>No Reseller Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={resellers}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>

            {/*   <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : resellers.length == 0 ? (
                <p>No Reseller Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Keys</th>
                          <th>Joined</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={d.id}>
                            <td>
                              <Link to={"/reseller/" + d._id}>{i + 1}</Link>
                            </td>
                            <td>
                              <Link to={"/reseller/" + d._id}>{d.name}</Link>
                            </td>
                            <td>{d.phone}</td>
                            <td>{d.email}</td>
                            <td>{d.keys ?? 0}</td>

                            <td>
                              {format(
                                new Date(d.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>
                            <td>
                              {d.status === "active" ? (
                                <Badge variant="success light">
                                  Activated
                                  <span className="ml-1 fa fa-check"></span>
                                </Badge>
                              ) : d.status === "deactive" ? (
                                <Badge variant="danger light">
                                  Deactivated
                                  <span className="ml-1 fa fa-ban"></span>
                                </Badge>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      changeAgentStatus(
                                        d.id,
                                        d.status === "active"
                                          ? "deactive"
                                          : "active"
                                      )
                                    }
                                  >
                                    {d.status === "active"
                                      ? "Deactive Now"
                                      : "Active Now"}
                                  </Dropdown.Item>

                                  <div className="dropdown-divider"></div>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onAllotKeys(d)}
                                  >
                                    Allot Keys
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Keys</th>
                          <th>Joined</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {resellers.length < (activePage + 1) * sort
                          ? resellers.length
                          : (activePage + 1) * sort}
                        &nbsp;of {resellers.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div> */}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Resellers;
