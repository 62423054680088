import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";
import { format } from "date-fns";

import { TwitterPicker, SketchPicker, SliderPicker } from "react-color";

import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Row,
  Col,
  Form,
} from "react-bootstrap";

import { CSVLink } from "react-csv";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DTable from "../components/Table";

import noIamage from "../../images/no-image-uploaded.png";
import { getAllProducts } from "../../store/actions/licActions";

const AgentDetails = ({ match, history }) => {
  const dispatch = useDispatch();

  const [reseller, setReseller] = useState(null);
  const [members, setMembers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [agents, setAgents] = useState([]);

  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");

  const [rebranding, setRebranding] = useState(false);

  const [counter, setCounter] = useState("");
  const [allotCounterLoading, setAllotCounterLoading] = useState(false);

  const [activeToggle, setActiveToggle] = useState("keys");
  const [sendMessage, setSendMessage] = useState(false);

  const [replay, setReplay] = useState(false);
  const [loading, setLoading] = useState(false);

  const [keys, setKeys] = useState([]);

  const [errors, setErrors] = useState({});
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [keyQty, setKeyQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [note, setNote] = useState("");

  const [activeKey, setActiveKey] = useState(null);
  const [editKeyModal, setEditKeyModal] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [enable, setEnable] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const [status, setStatus] = useState("0");

  const [product, setProduct] = useState("all");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [softwareName, setSoftwareName] = useState("");
  const [description, setDescription] = useState("");

  const [helpContact, setHelpContact] = useState("");
  const [helpEmail, setHelpEmail] = useState("");

  const [siteLink, setSiteLink] = useState("");
  const [demoVideoLink, setDemoVideoLink] = useState("");

  const [showAd, setShowAd] = useState(false);
  const [adBannerLink, setBannerAdLink] = useState("");
  const [adLink, setAdLink] = useState("");

  const [rebrand, setRebrand] = useState(false);
  const [rebrandProductList, setRebrandProductList] = useState([]);

  const [color, setColor] = useState({ hex: "#08094e" });

  const [filter, setFilter] = useState("all");

  const [editMemberModal, setEditMemberModal] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [version, setVersion] = useState(1);
  const [updateUrl, setUpdateUrl] = useState("");

  useEffect(() => {
    // loadResellerDetails(match.params.id);
    // loadMembers(match.params.id);
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    console.log("rebrandProductList - " + JSON.stringify(rebrandProductList));
  }, [rebrandProductList]);

  useEffect(() => {
    if (reseller) {
      console.log("reseller.rebrandProduct - " + JSON.stringify(reseller));

      if ((reseller.rebrandProducts ?? []).includes(selectedProduct)) {
        setEnable(true);
        setRebranding(true);
        loadRebrandDetails(selectedProduct, match.params.id);
      } else {
        setEnable(false);
        setRebranding(false);
      }

      // var product = products.find((x) => x._id == selectedProduct);
      // if (product) {

      // }else{
      //   setEnable(false);
      //   setRebranding(false);
      // }
    } else {
      setEnable(false);
      setRebranding(false);
    }
  }, [selectedProduct]);

  useEffect(() => {}, [match]);

  useEffect(() => {
    if (keys) {
      setLoading(false);
    }
  }, [keys]);

  const loadRebrandDetails = async (product_id, reseller_id) => {
    const { data } = await axiosInstance.post("admin/rebrand-detail", {
      //product_id: "62677b1d52f74219882d4f38",
      product_id: product_id,
      reseller_id: reseller_id,
    });

    if (data.status) {
      setSoftwareName(data.detail.name ?? "");
      setDescription(data.detail.description ?? "");

      setHelpContact(data.detail.contactNumber);
      setHelpEmail(data.detail.email);

      setShowAd(data.detail.showAd);
      setBannerAdLink(data.detail.adBannerUrl);
      setAdLink(data.detail.adUrl);

      setSiteLink(data.detail.siteUrl);
      setDemoVideoLink(data.detail.demoVideoUrl);

      setColor({ hex: data.detail.color });
      setEnable(data.detail.enable);

      setVersion(data.detail.version);
      setForceUpdate(data.detail.forceUpdate);
      setUpdateUrl(data.detail.updateUrl);
    } else {
      setSoftwareName("");
      setDescription("");

      setHelpContact("");
      setHelpEmail("");

      setShowAd(false);
      setBannerAdLink("");
      setAdLink("");

      setSiteLink("");
      setDemoVideoLink("");

      setEnable(true);

      setVersion("");
      setForceUpdate("");
      setUpdateUrl("");
    }

    console.log("details data - " + JSON.stringify(data));
  };

  useEffect(() => {
    loadResellerDetails(match.params.id);
    //loadRebrandDetails(match.params.id);
  }, [match]);

  const loadResellerDetails = async (userId) => {
    const { data } = await axiosInstance.get("admin/reseller/" + userId);

    if (data.status) {
      setReseller(data.reseller);
      //setKeys(data.reseller.keys);
      document.getElementById("nav_title").innerHTML = data.reseller.name
        ? data.reseller.name
        : data.reseller.email;
    }
  };

  function onCheckRebrandProduct(id, value) {
    console.log("onCheckRebrandProduct - " + id + ", value - " + value);

    if (rebrandProductList.includes(id) && !value) {
      setRebrandProductList(rebrandProductList.filter((x) => x != id));
    } else {
      setRebrandProductList([...rebrandProductList, id]);
    }
  }

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function productVideoDemo(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x.id === id);

      if (productIndex != -1) {
        return products[productIndex].demoVideoUrl;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const onDeactive = async (code) => {};

  const onChangeLicStatus = async (lic, status) => {
    const { data } = await axiosInstance.post("license/change-status", {
      license_id: lic._id,
      status: status,
    });

    if (data.status === false) {
      //toast.error(data.message);
      swal("Change license Status", data.message, "error");
    } else {
      swal("Change license Status", data.message, "success");
      // setTimeout(() => {
      //   loadKeys();
      // }, 500);

      var dummy = [...keys];

      const index = dummy.findIndex((x) => x._id === lic._id);

      dummy[index].enable = status;
      setKeys(dummy);
      onClick(activePage);
    }
  };

  const onEditLic = async (lic) => {
    setActiveKey(lic);
    setName(lic.name);
    setEmail(lic.email);
    setPhone(lic.phone);
    setCity(lic.place);
    setCountry(lic.country);
    setActiveDate(lic.activateAt ?? "");
    setExpireDate(lic.expireAt ?? "");
    setValid(lic.valid);
    setEnable(lic.enable);
    setStatus(lic.status);
    setNote(lic.note ?? "");
    setSelectedProduct(lic.productId);

    setEditKeyModal(true);
  };

  const onUpdateLicDetails = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (status != "0" && activeDate == "") {
      error = true;
      errorObj.activeDate = "Activate Date is Required";
    }

    if (status != "0" && expireDate == "") {
      error = true;
      errorObj.expireAt = "Expire Date is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("license/update-details", {
          license_id: activeKey._id,
          campaign_id: "",
          product_id: selectedProduct,
          enable: enable,
          status: Number(status),
          valid: Number(valid),

          activate_at: activeDate,
          expire_at: expireDate,

          name: name,
          email: email,
          phone: phone,
          place: city,
          country: country,

          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Update license", data.message, "error");
        } else {
          setEditKeyModal(false);
          //toast.success(data.message);
          swal("Update license", data.message, "success");

          setKeyQty("1");
          setValid("365");
          setCampaignName("");
          setNote("");
          setName("");
          setEmail("");
          setPhone("");
          setCity("");
          setCountry("");

          var dummy = [...keys];
          const index = dummy.findIndex((x) => x._id === activeKey._id);
          dummy[index].enable = enable;
          dummy[index].status = status;
          dummy[index].name = name;
          dummy[index].email = email;
          dummy[index].phone = phone;
          dummy[index].place = city;
          dummy[index].country = country;
          dummy[index].valid = valid;

          dummy[index].note = note;

          if (status == "0") {
            dummy[index].activateAt = null;
            dummy[index].expireAt = null;
          } else {
            dummy[index].activateAt = activeDate;
            dummy[index].expireAt = expireDate;
          }

          setKeys(dummy);
          onClick(activePage);
          // setTimeout(() => {
          //   loadKeys();
          // }, 500);
        }
      } catch (error) {}
    }
  };

  const initXlsData = async () => {
    const { data } = await axiosInstance.post("license/all", {
      filter: "all",
      product_id: product,
      search: search,
      page: 0,
      limit: maxPage * pageSize,
      reseller_id: match.params.id,
    });

    if (data.status) {
      console.log("total keys - " + data.keys.length);

      var dummy = [];
      data.keys.map((d, i) => {
        dummy.push({
          sr_no: i + 1,
          id: d.id,
          key: d.key,
          product_id: d.productId,
          product_name: productName(d.productId),
          valid: d.valid,
          status: d.status,
          name: d.name,
          phone: d.phone,
          email: d.email,
          place: d.place,
          country: d.country,
          activate_at: d.activateAt
            ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
            : "",
          expire_at: d.expireAt
            ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
            : "",
          note: d.note ?? "",
          created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactivate</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  const onGenerate = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (keyQty === "") {
      error = true;
      errorObj.keys = "Keys is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Key validity is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("license/allot-to-reseller", {
          reseller_id: match.params.id,
          product_id: selectedProduct,
          keys: Number(keyQty),
          valid: Number(valid),
          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Allot license", data.message, "error");
        } else {
          setGenerateKeyModal(false);
          //toast.success(data.message);
          swal("Allot license", data.message, "success");

          setKeyQty("1");
          setValid("365");
          setNote("");

          setTimeout(() => {
            loadResellerDetails(match.params.id);
            loadKeys(filter);
          }, 500);
        }
      } catch (error) {}
    }
  };

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadKeys(filter);
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadKeys(filter);
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loadKeys = async (filter) => {
    setLoading(true);
    const { data } = await axiosInstance.post("license/all", {
      filter: filter ?? "all",
      product_id: product,
      reseller_id: match.params.id,
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setKeys(data.keys);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Key",
      accessor: "key",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.key);
            }}
          >
            {d.key}
          </span>
        );
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "User",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            {d.name}
            <br />
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.place}</small>
            <br />
            <small>{d.country}</small>
          </div>
        );
      },
    },

    {
      Header: "Badge",
      accessor: "badge",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <>
            {(d.badge ?? []).map((x) => {
              return <Badge variant="primary light">{x}</Badge>;
            })}
          </>
        );
      },
    },

    {
      Header: "Product",
      accessor: "productId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return productName(d.productId);
      },
    },

    {
      Header: "Campaign",
      accessor: "campaignId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.campaignId ? d.campaignId.name : "";
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
    },

    {
      Header: "Ip",
      accessor: "ip",
      sort: true,
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return format(new Date(d.createdAt), "dd/MM/yyyy H:mma");
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {d.enable ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, false)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, true)}
                >
                  Enable
                </Dropdown.Item>
              )}

              <Dropdown.Item as="button" onClick={(e) => assignReseller(d)}>
                {d.resellerId ? "Change Reseller" : "Assign to Reseller"}
              </Dropdown.Item>

              {d.resellerId ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => removeResellerFromKey(d)}
                >
                  Remove Reseller
                </Dropdown.Item>
              ) : (
                <></>
              )}

              <Dropdown.Item as="button" onClick={(e) => onEditLic(d)}>
                Edit
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => {
                  history.push("/related-license-keys/" + d.key);
                }}
              >
                View Related License Key
              </Dropdown.Item>

              {d.resellerId.id === "62711bf7d52e87849e8cf7fa" ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => {
                    var mail = `Thanks for Ordering Digi Bulk Marketing - ${productName(
                      d.productId
                    )} (Email Delivery in 2 hours - No CD) from Amazon. Please find below the activation code for the product with the download and activation instructions:

Activation Code: ${d.key}
Validity: ${d.valid >= 36500 ? "Lifetime": (d.valid +" Days")}
Download Link: https://api.digibulkmarketing.com/app/download?id=${d.productId}
Video Demo: ${productVideoDemo(d.productId)}

Instructions :
${
  productName(d.productId).includes("Data Extractor")
    ? `1. Open Chrome Browser
2. Settings - icon > More Tools > Extensions. (the 'hamburger' icon in the top-right corner)
3. Enable Developer Mode (toggle button in top-right corner)
4. Click On Load unpacked > Choose unzipped extension folder
5. Click on extension icon. (the 'puzzle' icon in the top-right corner)
6. Pin the `+productName(d.productId)
    : `1. Download and activate the software by copy-pasting the above download link in a browser on your system.
2. Use the activation code provided above (e.g. XXXX-XXXX-XXXX-XXXX)
(Note: copy-paste of product key may not paste the full key. Check for any missing characters or white space)
3. Fill in the required details in the activation form and click on the "Active" button in the software.`
}

For any technical assistance
Toll Free: 1800 889 8358
WhatsApp: +917698845235 (10 AM to 6 PM on Business Days)
Website: https://digibulkmarketing.com

To get a printable order summary, login into your Amazon Website/App, go to your account and click on the invoice in the orders section: https://www.amazon.in/gp/css/order-history/`;

                    navigator.clipboard.writeText(mail);
                  }}
                >
                  Copy Amazon e-Mail
                </Dropdown.Item>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const changeKeyFilter = async (filter) => {
    await setFilter(filter);
    loadKeys(filter);
  };

  const onSaveDetails = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (softwareName === "") {
      errorObj.name = "Software Name is Required";
      error = true;
    }

    if (color.hex === "") {
      errorObj.color = "Color Hex Code is Required";
      error = true;
    }

    if (helpContact === "") {
      errorObj.contact = "Contact Number is Required";
      error = true;
    }

    if (helpEmail === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    if (siteLink === "") {
      errorObj.siteLink = "Website Link is Required";
      error = true;
    } else if (!siteLink.startsWith("http")) {
      errorObj.siteLink = "Enter valid Website Link";
      error = true;
    }

    if (demoVideoLink === "") {
      errorObj.demoVideoLink = "Demo Video Link is Required";
      error = true;
    } else if (!demoVideoLink.startsWith("http")) {
      errorObj.demoVideoLink = "Enter valid Website Link";
      error = true;
    }

    if (showAd) {
      if (adBannerLink === "") {
        errorObj.adBannerLink = "Ad Banner Link is Required";
        error = true;
      }

      if (adLink === "") {
        errorObj.adLink = "Ad Action Link is Required";
        error = true;
      } else if (!adLink.startsWith("http")) {
        errorObj.adLink = "Enter valid Ad Action Link";
        error = true;
      }
    }

    if (version === "") {
      errorObj.version = "Software version Required";
      error = true;
    }

    if (updateUrl === "") {
      errorObj.updateUrl = "Software Update Link is Required";
      error = true;
    } else if (!updateUrl.startsWith("http")) {
      errorObj.updateUrl = "Enter valid Software Update Link";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      product_id: selectedProduct, //"62677b1d52f74219882d4f38",
      reseller_id: match.params.id,
      enable: enable,

      name: softwareName,
      description: "",
      color: color.hex,

      contact_number: helpContact,
      email: helpEmail,

      show_ad: showAd,
      ad_banner_url: adBannerLink,
      ad_url: adLink,

      site_url: siteLink,
      demo_video_url: demoVideoLink,

      version: version,
      force_update: forceUpdate,

      update_url: updateUrl,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "admin/save-rebrand-detail",
      formData
    );

    if (data.status) {
      swal("Update Rebrand Setting", data.message, "success");
    } else {
      swal("Update Rebrand Setting", data.message, "error");
    }
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        //upload image
        var file = event.target.files[0];

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const formData = new FormData();

        formData.append("image", file, file.name);
        formData.append("type", "banner");

        const { data } = await axiosInstance.post(
          "app/upload-image",
          formData
        );

        //console.log("image upload response - "+data);
        console.log("image upload response - " + JSON.stringify(data));

        if (data.status) {
          swal("Image Upload", data.message, "success");
          setBannerAdLink(data.downloadUrl);
        } else {
          swal("Image Upload", data.message, "error");
        }
      } catch (err) {
        console.log(err);
        //setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  //edit
  function onEditMember() {
    setPhone(reseller.phone);
    setEmail(reseller.email);
    setName(reseller.name);
    setRebrand(reseller.rebrand);

    console.log("product list - " + JSON.stringify(reseller.rebrandProducts));
    setRebrandProductList(reseller.rebrandProducts ?? []);

    setEditMemberModal(true);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    } else if (!validateEmail(email)) {
      error = true;
      errorObj.email = "Enter Valid Email!";
    }

    setErrors(errorObj);
    if (!error) {
      const { data } = await axiosInstance.post("admin/update-reseller", {
        name: name,
        rebrand: rebrand,
        email: email,
        reseller_id: match.params.id,
        rebrand_products: rebrandProductList,
      });

      if (data.status === false) {
        swal("Update Reseller Details", data.message, "error");
      } else {
        setEditMemberModal(false);
        swal("Update Reseller Details", data.message, "success");

        setTimeout(() => {
          loadResellerDetails(match.params.id);
        }, 500);
      }
    }
  };

  return (
    <>
      <Modal
        size={"xl"}
        className="modal fade"
        show={editKeyModal}
        onHide={setEditKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onUpdateLicDetails}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  License Details -{" "}
                  {activeKey ? (
                    <small className="b">{activeKey.key}</small>
                  ) : (
                    ""
                  )}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>

              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label
                            id=""
                            column
                            md="4"
                            className="required-field"
                          >
                            Is Enable
                          </Form.Label>
                          <Col md="7">
                            <Form.Check
                              type="checkbox"
                              id={`isActive`}
                              label={``}
                              value={enable}
                              checked={enable}
                              onClick={(e) => setEnable(!enable)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Product:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={selectedProduct}
                              onChange={(e) =>
                                setSelectedProduct(e.target.value)
                              }
                            >
                              <option value="">Choose Product</option>
                              {products.map((p, i) => {
                                return (
                                  <option key={i} value={p.id}>
                                    {i + 1} - {p.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.product && (
                              <div className="text-danger fs-12">
                                {errors.product}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <h4 className="h4 text-dark mt-4">Key Details</h4>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Valid:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="number"
                              value={valid}
                              placeholder="Enter Days"
                              className="form-control"
                              required="true"
                              min={0}
                              max={36500}
                              onChange={(e) => {
                                setValid(e.target.value);

                                var currentDate = activeDate; //Date.now();
                                var ed = moment(currentDate);
                                ed.add(e.target.value, "days").format(
                                  "YYYY-MM-DD hh:mm"
                                );

                                setExpireDate(ed);
                              }}
                            />{" "}
                            {errors.valid && (
                              <div className="text-danger fs-12">
                                {errors.valid}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Status:
                          </Form.Label>

                          <Col md="7">
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="0">Available</option>
                              <option value="1">Active</option>
                              {/*  <option value="2">Deactive</option> */}
                              <option value="3">Expire</option>
                            </select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    {status != "0" ? (
                      <Row>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Activation Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Activation Date"
                                  value={activeDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setActiveDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate = moment(date).startOf("day");
                                    var endDate =
                                      moment(expireDate).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />

                                {/* <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Activated Date"
                                format="dd/MM/yyyy hh:mm"
                                placeholder="dd/MM/yyyy hh:mm"
                                value={activeDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setActiveDate(date)}
                              /> */}
                              </MuiPickersUtilsProvider>

                              {errors.activeDate && (
                                <div className="text-danger fs-12">
                                  {errors.activeDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          {" "}
                          <Form.Group as={Row} className="mb-3 ">
                            <Form.Label
                              column
                              md="4"
                              className="required-field"
                            >
                              Expire Date :
                            </Form.Label>
                            <Col md="7">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  inputVariant="outlined"
                                  label="Expire Date"
                                  value={expireDate}
                                  format="dd/MM/yyyy hh:mm"
                                  placeholder="dd/MM/yyyy hh:mm"
                                  onChange={(date) => {
                                    setExpireDate(date);

                                    // var currentDate = activeDate; //Date.now();
                                    // var ed = moment(currentDate);
                                    // ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                                    // setExpireDate(ed);

                                    var startDate =
                                      moment(activeDate).startOf("day");
                                    var endDate = moment(date).startOf("day");

                                    var result = endDate.diff(
                                      startDate,
                                      "days"
                                    );
                                    setValid(result);
                                  }}
                                />
                              </MuiPickersUtilsProvider>

                              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Expired Date"
                                format="dd/MM/yyyy hh:mm"
                                placeholder="dd/MM/yyyy hh:mm"
                                value={expireDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setExpireDate(date)}
                              />
                            </MuiPickersUtilsProvider> */}

                              {errors.expireDate && (
                                <div className="text-danger fs-12">
                                  {errors.expireDate}
                                </div>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}

                    <h4 className="h4 text-dark mt-4">User Details</h4>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Name:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="text"
                              value={name}
                              placeholder="Enter Name"
                              className="form-control"
                              onChange={(e) => setName(e.target.value)}
                            />{" "}
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            HID:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="text"
                              value={activeKey ? activeKey.deviceId ?? "" : ""}
                              placeholder="Hardware ID"
                              className="form-control"
                              readOnly
                            />{" "}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="4" className="required-field">
                            Email:
                          </Form.Label>

                          <Col md="7">
                            <Form.Control
                              type="email"
                              value={email}
                              placeholder="Enter Email Id"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />{" "}
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Phone Number :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            {errors.phone && (
                              <div className="text-danger fs-12">
                                {errors.phone}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Place/City :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              placeholder="Enter City Name"
                              className="form-control"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                            {errors.city && (
                              <div className="text-danger fs-12">
                                {errors.city}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Country :
                          </Form.Label>
                          <Col md="7">
                            <Form.Control
                              placeholder="Enter Country Name"
                              className="form-control"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                            {errors.country && (
                              <div className="text-danger fs-12">
                                {errors.country}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        {" "}
                        <Form.Group as={Row} className="mb-3 ">
                          <Form.Label column md="4" className="required-field">
                            Note :
                          </Form.Label>
                          <Col md="7">
                            <textarea
                              rows={3}
                              placeholder="Enter Note"
                              className="form-control"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditKeyModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"> </i>
                  Close
                </button>

                <button type="submit" className="btn btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={generateKeyModal}
        onHide={setGenerateKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGenerate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Allot Keys</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGenerateKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500"> Products</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                          <option value="">Choose Product</option>
                          {products.map((p, i) => {
                            return (
                              <option key={i} value={p.id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.product && (
                          <div className="text-danger fs-12">
                            {errors.product}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Keys</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={keyQty}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setKeyQty(e.target.value)}
                          placeholder="Total Keys"
                        />
                        {errors.keys && (
                          <div className="text-danger fs-12">{errors.keys}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Validity</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          required="required"
                          onChange={(e) => setValid(e.target.value)}
                          placeholder="Validity in days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Note</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          value={note}
                          rows={4}
                          className="form-control"
                          autocomplete="off"
                          required="required"
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Note"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGenerateKeyModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Allot
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={setEditMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Reseller Details</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={phone}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          readOnly
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone Number with country code"
                        />
                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group">
                        <label>Rebrand</label>

                        <Form.Check
                          id="rebrand"
                          label="Enable"
                          defaultChecked={rebrand}
                          value={rebrand}
                          onChange={(e) => setRebrand(e.target.checked)}
                        />

                        {rebrand ? (
                          <div className="mt-2">
                            {products.map((x, i) => {
                              return (
                                <Form.Check
                                  key={"cb-product-" + i}
                                  id={"cb-product-" + i}
                                  label={x.name}
                                  defaultChecked={rebrandProductList.includes(
                                    x.id
                                  )}
                                  value={rebrandProductList.includes(x.id)}
                                  onChange={(e) =>
                                    onCheckRebrandProduct(
                                      x.id,
                                      e.target.checked
                                    )
                                  }
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            {reseller == null ? (
              <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                      <div className="profile-head">
                        <div className="photo-content"></div>
                        <div className="profile-info">
                          <div className="profile-photo"></div>
                          <div className="profile-details">
                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                {reseller.name}
                              </h4>
                              <p>{reseller.phone}</p>
                              <p>{reseller.email}</p>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.keys}
                                <small className="text-dark">
                                  <br />
                                  Total
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.availableKeys}
                                <small className="text-dark">
                                  <br />
                                  Available
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.activeKeys}
                                <small className="text-dark">
                                  <br />
                                  Active
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.expireKeys}
                                <small className="text-dark">
                                  <br />
                                  Expire
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.trialKeys}
                                <small className="text-dark">
                                  <br />
                                  Trial
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              {(reseller.badge ?? []).map((x) => {
                                return (
                                  <Badge variant="primary light">{x}</Badge>
                                );
                              })}
                            </div>

                            <div className="profile-name ml-auto">
                              <Button
                                className="btn btn-primary btn-sm font-w600 mr-2"
                                onClick={() => onEditMember()}
                              >
                                Edit Profile
                              </Button>

                              <Button
                                className="btn btn-primary btn-sm font-w600 mr-2"
                                onClick={() => setGenerateKeyModal(true)}
                              >
                                Allot Keys
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="profile-tab">
                          <div className="custom-tab-1">
                            <ul className="nav nav-tabs">
                              <li
                                className="nav-item"
                                onClick={() => setActiveToggle("keys")}
                              >
                                <Button
                                  data-toggle="tab"
                                  className={`nav-link ${
                                    activeToggle === "keys" ? "active show" : ""
                                  }`}
                                >
                                  License Keys
                                </Button>
                              </li>

                              <li
                                className="nav-item"
                                onClick={() => setActiveToggle("rebranding")}
                              >
                                <Button
                                  data-toggle="tab"
                                  className={`nav-link ${
                                    activeToggle === "rebranding"
                                      ? "active show"
                                      : ""
                                  }`}
                                >
                                  Rebranding
                                </Button>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="keys"
                                className={`tab-pane fade ${
                                  activeToggle === "keys" ? "active show" : ""
                                }`}
                              >
                                <div className="my-post-content pt-3">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="input-group search-area">
                                        <form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            if (searchTimeout) {
                                              clearTimeout(searchTimeout);
                                            }
                                            loadCampaigns();
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here"
                                            onChange={(e) => {
                                              setSearch(e.target.value);
                                            }}
                                          />
                                        </form>

                                        <div className="input-group-append">
                                          <span className="input-group-text">
                                            <svg
                                              width={20}
                                              height={20}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                                                fill="#A4A4A4"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 text-right">
                                      <div class="dropdown d-inline-block">
                                        <Dropdown className="btn btn-sm">
                                          <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                          >
                                            {filter} Keys
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                changeKeyFilter("all")
                                              }
                                            >
                                              All Keys
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                changeKeyFilter("available")
                                              }
                                            >
                                              Available Keys
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                changeKeyFilter("active")
                                              }
                                            >
                                              Active Keys
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                changeKeyFilter("expired")
                                              }
                                            >
                                              Expired Keys
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                changeKeyFilter("trial")
                                              }
                                            >
                                              Trial Keys
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>

                                        <CSVLink
                                          data={xlsData}
                                          filename={
                                            "keys-" + match.params.id + ".csv"
                                          }
                                          className="btn btn-primary btn-sm"
                                          target="_blank"
                                          asyncOnClick={true}
                                          onClick={async (event, done) => {
                                            console.log("You click the link");
                                            await initXlsData();
                                            done(); // 👍🏻 You are stopping the handling of component
                                          }}
                                        >
                                          Export
                                        </CSVLink>
                                      </div>
                                    </div>
                                  </div>

                                  {loading ? (
                                    <div className="text-center mt-4">
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                      />
                                    </div>
                                  ) : keys.length == 0 ? (
                                    <p>No License Keys Found</p>
                                  ) : keys.length == 0 ? (
                                    <p>No keys found</p>
                                  ) : (
                                    <DTable
                                      columns={columns}
                                      data={keys}
                                      pageSize={pageSize}
                                      isSortable={true}
                                      pagination={false}
                                      pageSizes={pageSizes}
                                      page={page}
                                      maxPage={maxPage}
                                      handlePageChange={handlePageChange}
                                      handlePageSizeChange={
                                        handlePageSizeChange
                                      }
                                    />
                                  )}
                                </div>
                              </div>

                              <div
                                id="rebranding"
                                className={`tab-pane fade ${
                                  activeToggle === "rebranding"
                                    ? "active show"
                                    : ""
                                }`}
                              >
                                <div className="form-row mt-4">
                                  <div className="form-group mb-3">
                                    <label className="text-black font-w500">
                                      Product
                                    </label>
                                    <select
                                      className="form-control"
                                      value={selectedProduct}
                                      onChange={(e) =>
                                        setSelectedProduct(e.target.value)
                                      }
                                    >
                                      <option value="select">
                                        Choose Product
                                      </option>
                                      {products
                                        .filter((x) => x.enable == true)
                                        .map((p, i) => {
                                          return (
                                            <option key={i} value={p.id}>
                                              {i + 1} - {p.name}
                                            </option>
                                          );
                                        })}
                                    </select>

                                    {errors.product && (
                                      <div className="text-danger fs-12">
                                        {errors.product}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {reseller.rebrand &&
                                (reseller.rebrandProducts ?? []).includes(
                                  selectedProduct
                                ) ? (
                                  <div id="">
                                    <div className="pt-3">
                                      <div className="settings-form">
                                        <form onSubmit={onSaveDetails}>
                                          <>
                                            <div className="form-row">
                                              <div className="form-group col-md-6">
                                                <label className="required-field">
                                                  Software Name
                                                </label>
                                                <input
                                                  type="text"
                                                  value={softwareName}
                                                  onChange={(e) =>
                                                    setSoftwareName(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Name"
                                                  className="form-control"
                                                />
                                                {errors.name && (
                                                  <div className="text-danger fs-12">
                                                    {errors.name}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="form-group col-md-6">
                                                <label>Software Color</label>

                                                <div className="row">
                                                  <div className="form-group col-md-6">
                                                    <TwitterPicker
                                                      color={color}
                                                      triangle="hide"
                                                      onChangeComplete={
                                                        setColor
                                                      }
                                                    />
                                                  </div>
                                                  <div className="form-group ml-auto col-md-4">
                                                    <div
                                                      style={{
                                                        height: "100px",
                                                        background: color.hex,
                                                        borderRadius:
                                                          "6px 6px 6px 6px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          fontSize: "18px",
                                                          color:
                                                            "rgb(255, 255, 255)",
                                                          position: "relative",
                                                        }}
                                                      >
                                                        {color.hex}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <SliderPicker
                                                  color={color}
                                                  triangle="hide"
                                                  onChangeComplete={setColor}
                                                />

                                                {errors.color && (
                                                  <div className="text-danger fs-12">
                                                    {errors.color}
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div className="form-row">
                                              <div className="form-group col-md-6">
                                                <label>
                                                  Contact Number{" "}
                                                  <small>
                                                    (Help &amp; Support)
                                                  </small>
                                                </label>
                                                <input
                                                  type="text"
                                                  value={helpContact}
                                                  onChange={(e) =>
                                                    setHelpContact(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Contact Number"
                                                  className="form-control"
                                                />
                                                {errors.contact && (
                                                  <div className="text-danger fs-12">
                                                    {errors.contact}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="form-group col-md-6">
                                                <label>
                                                  Email Address{" "}
                                                  <small>
                                                    (Help &amp; Support)
                                                  </small>
                                                </label>
                                                <input
                                                  type="email"
                                                  value={helpEmail}
                                                  onChange={(e) =>
                                                    setHelpEmail(e.target.value)
                                                  }
                                                  placeholder="Email Address"
                                                  className="form-control"
                                                />
                                                {errors.email && (
                                                  <div className="text-danger fs-12">
                                                    {errors.email}
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div className="form-row">
                                              <div className="form-group col-md-6">
                                                <label>Main Site Url</label>
                                                <input
                                                  type="text"
                                                  value={siteLink}
                                                  onChange={(e) =>
                                                    setSiteLink(e.target.value)
                                                  }
                                                  placeholder="e.g https://"
                                                  className="form-control"
                                                />
                                                {errors.siteLink && (
                                                  <div className="text-danger fs-12">
                                                    {errors.siteLink}
                                                  </div>
                                                )}
                                              </div>

                                              <div className="form-group col-md-6">
                                                <label>Video Demo Url</label>
                                                <input
                                                  type="text"
                                                  value={demoVideoLink}
                                                  onChange={(e) =>
                                                    setDemoVideoLink(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="e.g https://"
                                                  className="form-control"
                                                />
                                                {errors.demoVideoLink && (
                                                  <div className="text-danger fs-12">
                                                    {errors.demoVideoLink}
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div className="form-row">
                                              <div className="form-group col-md-3">
                                                <label>Show Ads?</label>

                                                <select
                                                  className="form-control"
                                                  value={showAd ? "yes" : "no"}
                                                  onChange={(e) =>
                                                    setShowAd(
                                                      e.target.value == "yes"
                                                    )
                                                  }
                                                  defaultValue="yes"
                                                >
                                                  <option value="yes">
                                                    Yes
                                                  </option>
                                                  <option value="no">No</option>
                                                </select>
                                              </div>

                                              <div className="form-group col-md-9">
                                                <label>Ads Banner Url</label>
                                                <input
                                                  type="text"
                                                  value={adBannerLink}
                                                  onChange={(e) =>
                                                    setBannerAdLink(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="e.g https://"
                                                  className="form-control"
                                                />
                                                {errors.adBannerLink && (
                                                  <div className="text-danger fs-12">
                                                    {errors.adBannerLink}
                                                  </div>
                                                )}
                                              </div>

                                              <div className="col-md-3">
                                                <div className="text-center">
                                                  <div
                                                    className="card"
                                                    style={{
                                                      background: "#F5F5F5",
                                                    }}
                                                  >
                                                    <img
                                                      onClick={upload}
                                                      className="img img-fluid my-4"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      src={
                                                        adBannerLink != ""
                                                          ? adBannerLink
                                                          : noIamage
                                                      }
                                                    ></img>
                                                    <input
                                                      id="selectFile"
                                                      type="file"
                                                      hidden
                                                      onChange={
                                                        fileChangedHandler
                                                      }
                                                      onClick={fileClickHandler}
                                                    />

                                                    <div className="card-footer">
                                                      <Button
                                                        onClick={upload}
                                                        className="btn btn-sm btn-success"
                                                      >
                                                        Upload
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-9">
                                                <div className="form-group">
                                                  <label>Ads Action Url</label>
                                                  <input
                                                    type="text"
                                                    value={adLink}
                                                    onChange={(e) =>
                                                      setAdLink(e.target.value)
                                                    }
                                                    placeholder="e.g https://"
                                                    className="form-control"
                                                  />
                                                  {errors.adLink && (
                                                    <div className="text-danger fs-12">
                                                      {errors.adLink}
                                                    </div>
                                                  )}
                                                </div>

                                                <div className="form-row">
                                                  <div className="form-group col-md-6">
                                                    <label>Version</label>
                                                    <input
                                                      type="number"
                                                      value={version}
                                                      onChange={(e) =>
                                                        setVersion(
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder="Software Version"
                                                      className="form-control"
                                                    />
                                                    {errors.version && (
                                                      <div className="text-danger fs-12">
                                                        {errors.version}
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="form-group col-md-6">
                                                    <label>Force Update</label>
                                                    <select
                                                      className="form-control"
                                                      value={
                                                        forceUpdate
                                                          ? "yes"
                                                          : "no"
                                                      }
                                                      onChange={(e) =>
                                                        setForceUpdate(
                                                          e.target.value ==
                                                            "yes"
                                                        )
                                                      }
                                                      defaultValue="yes"
                                                    >
                                                      <option value="yes">
                                                        Yes
                                                      </option>
                                                      <option value="no">
                                                        No
                                                      </option>
                                                    </select>
                                                  </div>

                                                  <div className="form-group col-md-12">
                                                    <label>Update Url</label>
                                                    <input
                                                      type="text"
                                                      value={updateUrl}
                                                      onChange={(e) =>
                                                        setUpdateUrl(
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder="https://"
                                                      className="form-control"
                                                    />
                                                    {errors.updateUrl && (
                                                      <div className="text-danger fs-12">
                                                        {errors.updateUrl}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>

                                          <div className="text-center my-4">
                                            <button
                                              className="btn btn-lg btn-primary"
                                              type="submit"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                ) : selectedProduct != "select" ? (
                                  <>
                                    Rebranding is not enable for this reseller.
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AgentDetails;
