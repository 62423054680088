import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from "react";
import { Table,Modal, Pagination, Badge, Dropdown, Spinner } from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import UserTable from "./UsersTable";

import { CSVLink } from "react-csv";

//const Orders = () => {
const Users = ({ props, history }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);



  const [addMemberModal, setAddMemberModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  let errorsObj = { name: '',email:"",phone:""};
  const [errors, setErrors] = useState(errorsObj);

  const [editMemberModal, setEditMemberModal] = useState(false);
  const [activeMember, setActiveMember] = useState("");


  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadUsers();
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      setLoading(false);
    }
  }, [users]);

  const loadUsers = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("admin/users", {
      search: search,
    });

    if (data.status) {
      setUsers(data.users);
    }
    setLoading(false);
  };

  
  const initXlsData = async () => {

   var dummy = [];
   users.map((data,i)=>{

   
      dummy.push({
         sr_no:i+1,
         id:data.id,
         name:data.name,
         email:data.email,
         phone:data.phone,
         
         agent:data.agentId ? data.agentId.name : "",
         //agent_email:data.agentId ? data.agentId.email : "",
         counters:data.counter,

         status:data.status,

         created_at:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),

         
      });
   });


   setXlsData(dummy);


}


function onEditMember(member){
   setActiveMember(member.id);

   setPhone(member.phone.substring(member.phone.length - 10));
   setEmail(member.email);
   setName(member.name);

   setEditMemberModal(true);
}

const onSaveMember = async (event)=> {
   event.preventDefault();   
   let error = false;
   var errorObj = { };

   if(name === ""){
      error = true;
      errorObj.name = 'Name is Required';
   }

   if(phone === ""){
      error = true;
      errorObj.phone = 'Phone Number is Required';
  }else if(phone.length != 10 ){
   error = true;
   errorObj.phone = 'Phone number is not valid';
  }

   if(email === ""){
      error = true;
      errorObj.email = 'Email is Required';
   }

   setErrors(errorObj);
   if(!error){
   
      try {
      
         const { data } = await axiosInstance.post(
            "admin/update-user",
            { name:name,phone:"+91"+phone,email:email,user_id:activeMember}
         );

     if(data.status === false){
        
         swal('Update User', data.message,"error");

     }else{

         setEditMemberModal(false);
         swal('Update User', data.message, "success");

         
         
         setTimeout(() => {
            loadUsers();

            // var dummy = [...users];

            // const index = dummy.findIndex((x) => x.id === activeMember);
      
            // dummy[index].name = name;
            // dummy[index].phone = "+91"+phone;
            // dummy[index].email = email;
            // console.log("order index - " + index);
      
            // setUsers(users);
            // //onClick(activePage);

            // setPhone("");
            // setEmail("");
            // setName("");

            }, 500)

      }
      
      }catch (error) {
      
      }


   }
}



const onAddMember = async (event)=> {
   event.preventDefault();    
   let error = false;
   var errorObj = { };

   if(name === ""){
      error = true;
      errorObj.name = 'Name is Required';
   }

   if(phone === ""){
       error = true;
       errorObj.phone = 'Phone Number is Required';
   }else if(phone.length != 10 ){
    error = true;
    errorObj.phone = 'Phone number is not valid';
   }
   
   if(email === ""){
      error = true;
      errorObj.email = 'Email is Required';
   }

   setErrors(errorObj);
   if(!error){
   
      try {
      
         const { data } = await axiosInstance.post(
            "admin/add-user",
            { name:name,phone:"+91"+phone,email:email}
         );

     if(data.status === false){
        //toast.error(data.message);
      swal('Add User', data.message,"error");

     }else{
      setAddMemberModal(false);
        //toast.success(data.message);
      swal('Add User', data.message, "success");

      setPhone("");
      setEmail("");
      setName("");
       
        setTimeout(() => {
         loadUsers();
         }, 500)
      }
      
      }catch (error) {
      
      }


   }

}


  return (
    <Fragment>

            <Modal className="modal fade"  show={addMemberModal} onHide={setAddMemberModal} >
					<div className="" role="document">
						<div className="">
							<form onSubmit={onAddMember}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add User</h4>
									<button type="button" className="close" onClick={()=> setAddMemberModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                              <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text" value={name}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setName(e.target.value)
                                          }
														placeholder="Name"
													/>
                                       {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

										<div className="form-group mb-3">
												<label className="text-black font-w500">Phone Number</label>
												<div className="contact-name">
													<input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="10 Digit Phone Number"
													/>
                                       {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<input type="email" value={email}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setEmail(e.target.value)
                                          }
														placeholder="Email"
													/>
                                       {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setAddMemberModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary">Add</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>

 
            <Modal className="modal fade" show={editMemberModal} onHide={setEditMemberModal} >
					<div className="" role="document">
						<div className="">
							<form onSubmit={onSaveMember}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Edit User</h4>
									<button type="button" className="close" onClick={()=> setEditMemberModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                              <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text" value={name}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setName(e.target.value)
                                          }
														placeholder="Name"
													/>
                                       {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

										<div className="form-group mb-3">
												<label className="text-black font-w500">Phone Number</label>
												<div className="contact-name">
													<input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="10 Digit Phone Number"
													/>
                                       {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<input type="email" value={email}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setEmail(e.target.value)
                                          }
														placeholder="Email"
													/>
                                       {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setEditMemberModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary">Save</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>

      {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div class="mr-auto">
              <h4 className="card-title">Users</h4>
            </div>

            <div class="">
              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadUsers();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <Link className="btn btn-light font-w600 mr-2" onClick={()=> setAddMemberModal(true)}>Add User</Link>
				    

              <CSVLink
  data={xlsData}
  filename={"users.csv"}
  className="btn btn-primary"
  target="_blank"
  asyncOnClick={true}
  onClick={async (event, done) => {
   console.log("You click the link");
   await initXlsData();
   done(); // 👍🏻 You are stopping the handling of component
 }}

>
  Export
</CSVLink>

            </div>
          </div>

          <div className="card-body">
            {loading ? (
              <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : users.length == 0 ? (
              <p>No User Found</p>
            ) : (
              <UserTable users={users} onEditMember={onEditMember}></UserTable>
            )
            /*   <Table responsive className="w-100">
       <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
             <thead>
                <tr role="row">
                   <th key="0">User Id</th>
                   <th key="1">Name</th>
                   <th key="2">Phone</th>
                   <th key="3">Email</th>
                   <th>
                                 <strong>Available Counters</strong>
                              </th>
                   <th key="4">Joined</th>
                   <th key="5">Status</th>
                   <th key="6">Action</th>
                </tr>
             </thead>
             <tbody style={{'height': '300px'}}>
                {tableData.map((d, i) => (
                   <tr key={i}>

                     
                      <td >#{d.id}</td> 
                      <td>{d.name}</td>
                     
                      <td>{d.phone}</td>
                      <td>{d.email}</td>
                      <td>{d.counter}</td>
                     
                      <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                      <td>

                      {d.status === "active"? (
                      
                            <Badge variant="success light">
                                  Activated
                                  <span className="ml-1 fa fa-check"></span>
                            </Badge>)
                            
                      :d.status === "deactive" ? (
                               <Badge variant="danger light">
                                  Deactivated
                                  <span className="ml-1 fa fa-ban"></span>
                               </Badge>
                            ):(<></>)
                      }


                      </td>
                     
                     
                      <td>

                      <Dropdown>
                                  <Dropdown.Toggle
                                     variant=""
                                     className="table-dropdown icon-false"
                                  >
                                     <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                     >
                                        <g
                                           stroke="none"
                                           strokeWidth="1"
                                           fill="none"
                                           fillRule="evenodd"
                                        >
                                           <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                           ></rect>
                                           <circle
                                              fill="#000000"
                                              cx="5"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="12"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="19"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                        </g>
                                     </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                     <Dropdown.Item as="button" onClick={(e)=>changeUserStatus(d.id,d.status === "active" ? "deactive":"active")}>
                                          {d.status === "active" ? "Deactive Now":"Active Now"}
                                     </Dropdown.Item>
                                    
                                  </Dropdown.Menu>
                               </Dropdown>

                      </td>

                      
                   </tr>
                ))}
             </tbody>
             <tfoot>
                <tr role="row">

                <th key="0">User Id</th>
                   <th key="1">Name</th>
                   <th key="2">Phone</th>
                   <th key="3">Email</th>
                   <th>
                                 <strong>Available Counters</strong>
                              </th>
                   <th key="4">Joined</th>
                   <th key="5">Status</th>
                   <th key="6">Action</th>

                </tr>
             </tfoot>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
             <div className="dataTables_info">
                Showing {activePage * sort + 1} to 
                {users.length <
                (activePage + 1) * sort
                   ? users.length
                   : (activePage + 1) * sort}
                &nbsp;of {users.length} entries
             </div>
             <div className="dataTables_paginate paging_simple_numbers">
                <Pagination
                   className="pagination-primary pagination-circle"
                   size="lg"
                >
                   <li
                      className="page-item page-indicator "
                      onClick={() =>
                         activePage > 1 &&
                         onClick(activePage - 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-left" />
                      </Link>
                   </li>
                   {pagination.map((number, i) => (
                         <Pagination.Item
                         key={"page-"+i}
                         className={
                            activePage === i ? "active" : ""
                         }
                         onClick={() => onClick(i)}
                      >
                         {number}
                      </Pagination.Item>
                   ))}
                   <li
                      className="page-item page-indicator"
                      onClick={() =>
                         activePage + 1 <
                            pagination.length &&
                         onClick(activePage + 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-right" />
                      </Link>
                   </li>
                </Pagination>
             </div>
          </div>
       </div>
    </Table> */
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
