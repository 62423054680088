import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";
import Select from "react-select";

const UserTable = ({ users,onEditMember, props, history }) => {
  const dispatch = useDispatch();

  const sort = users.length > 100 ? Math.round(users.length / 15) : 100;

  let pagination = Array(Math.ceil(users.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [usersList, setUsersList] = useState(users);

  const [activeUser, setActiveUser] = useState(null);
  const [agents, setAgents] = useState([]);

  const [activeAgent, setActiveAgent] = useState(null);

  const [agentModal, setAgentModal] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");

  const [transferModal, setTransferModal] = useState(false);

  useEffect(() => {
    setTableData(usersList.slice(activePage * sort, (activePage + 1) * sort));
  }, [dispatch]);

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(usersList.slice(i * sort, (i + 1) * sort));
  };

  // transfer counter
  const [transferCounterFormData, setTransferCounterFormData] = useState({
    counter: "",
  });

  // update member function
  const handleTransferCounterFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...transferCounterFormData };
    newFormData[fieldName] = fieldValue;
    setTransferCounterFormData(newFormData);
  };

  // Update Profile Submit data
  const handleTransferCounterFormSubmit = async (event) => {
    event.preventDefault();

    var error = false;
    var errorMsg = "";

    if (transferCounterFormData.counter === "") {
      error = true;
      errorMsg = "Counter is Required";
    }

    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/reward-counter", {
          counter: transferCounterFormData.counter,
          user_id: activeUser.id,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Reward Counter", data.message, "error");
        } else {
          //toast.success(data.message);
          swal("Reward Counter", data.message, "success");
          transferCounterFormData.counter = "";

          setTimeout(() => {
            setTransferModal(false);
          }, 500);
        }
      } catch (error) {}
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  // On Edit Profile
  const onSendCounter = (member) => {
    const newFormData = { counter: 0 };
    setTransferCounterFormData(newFormData);
    setActiveUser(member);
    setTransferModal(true);
  };

  const removeAgentFromUser = async (user) => {
    const { data } = await axiosInstance.post("admin/remove-agent", {
      user_id: user.id,
    });

    if (data.status) {
      swal("Remove Agent", data.message, "success");
      //user.agentId = null;

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === user.id);

      dummy[index].agentId = null;
      console.log("user index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("Remove Agent", data.message, "error");
    }
  };

  const handleAssignAgent = async () => {
    const { data } = await axiosInstance.post("admin/assign-agent", {
      user_id: activeUser.id,
      agent_id: selectedAgentId.value,
    });

    if (data.status) {
      swal("Assign Agent", data.message, "success");
      setAgentModal(false);

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === activeUser.id);

      const agent = agents.find((agent) => agent.id === selectedAgentId.value);

      dummy[index].agentId = agent;
      console.log("user index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("Assign Agent", data.message, "error");
    }
  };

  const assignAgent = async (user) => {
    loadAgents();
    setActiveUser(user);
    setSelectedAgentId(
      user.agentId ? { value: user.agentId.id, label: user.agentId.name } : ""
    );
    setAgentModal(true);
  };

  const loadAgents = async () => {
    const { data } = await axiosInstance.post("admin/agents");

    if (data.status) {
      setAgents(data.agents);
    }
  };

  const agentOption = () => {
    let options = [];
    for (let key in agents) {
      options.push({
        value: agents[key].id,
        label: agents[key].name + " - Level " + agents[key].agentLevel,
      });
    }
    return options;
  };

  const changeUserStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("User", data.message, "success");

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === userId);

      dummy[index].status = status;
      console.log("order index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("User", data.message, "error");
    }
  };

  const makeAgent = async (userId) => {
    const { data } = await axiosInstance.post("admin/make-agent", {
      user_id: userId
    });

    if (data.status) {
      swal("Make Agent", data.message, "success");

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === userId);

      //dummy[index].status = status;
      console.log("order index - " + index);
      dummy.splice(index, 1); 

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("User", data.message, "error");
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={transferModal}
        onHide={setTransferModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Reward Counter</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setTransferModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Give reward counters to{" "}
                      {activeUser ? activeUser.name : ""}(
                      {activeUser ? activeUser.phone : ""}) to
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Counter</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={transferCounterFormData.counter}
                          className="form-control"
                          autocomplete="off"
                          name="counter"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          onChange={handleTransferCounterFormChange}
                          placeholder="Counter"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setTransferModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleTransferCounterFormSubmit}
                >
                  Give
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal className="modal fade" show={agentModal} onHide={setAgentModal}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Assign Agent</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAgentModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Assign agent to {activeUser ? activeUser.name : ""} (
                      {activeUser ? activeUser.phone : ""}) to
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Agent</label>
                      <div className="contact-name">
                        <Select
                          defaultValue={selectedAgentId}
                          onChange={setSelectedAgentId}
                          options={agentOption()}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAgentModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleAssignAgent()}
                >
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div responsive className="w-100 table-responsive">
        <Table className="display w-100 dataTable table table-striped table-bordered">
          <thead>
            <tr role="row">
              <th key="0">User Id</th>
              <th key="1">Name</th>
              <th key="2">Phone</th>

              <th>Counters</th>
              <th key="4">Agent</th>
              <th key="4">Joined</th>
              <th key="5">Status</th>
              <th key="6">Action</th>
            </tr>
          </thead>
          <tbody style={{ height: "300px" }}>
            {tableData.map((d, i) => (
              <tr key={i}>
                <td>
                  <small>#{d.id}</small>
                </td>
                <td>
                  {d.name}
                  <br />
                  <small>{d.email}</small>
                </td>

                <td>{d.phone}</td>

                <td>
                  <strong>{d.counter}</strong>
                </td>
                <td>{d.agentId ? d.agentId.name : ""}</td>

                <td>{format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                <td>
                  {d.status === "active" ? (
                    <Badge variant="success light">
                      Activated
                      <span className="ml-1 fa fa-check"></span>
                    </Badge>
                  ) : d.status === "deactive" ? (
                    <Badge variant="danger light">
                      Deactivated
                      <span className="ml-1 fa fa-ban"></span>
                    </Badge>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="table-dropdown icon-false"
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={(e) =>
                          changeUserStatus(
                            d.id,
                            d.status === "active" ? "deactive" : "active"
                          )
                        }
                      >
                        {d.status === "active" ? "Deactive Now" : "Active Now"}
                      </Dropdown.Item>

                      <Dropdown.Item as="button" onClick={(e)=>onEditMember(d)}>
                                                   Edit Profile
                                                </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={(e) => onSendCounter(d)}
                      >
                        Give Reward Counters
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={(e) => assignAgent(d)}
                      >
                        {d.agentId ? "Change User's Agent" : "Assign Agent"}
                      </Dropdown.Item>

                      {d.agentId ? (
                        <Dropdown.Item
                          as="button"
                          onClick={(e) => removeAgentFromUser(d)}
                        >
                          Remove Agent
                        </Dropdown.Item>
                      ) : (
                        <></>
                      )}


                        <Dropdown.Item
                          as="button"
                          onClick={(e) => makeAgent(d.id)}
                        >
                          Make Agent
                        </Dropdown.Item>
                      )

                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr role="row">
              <th key="0">User Id</th>
              <th key="1">Name</th>
              <th key="2">Phone</th>

              <th>Counters</th>
              <th key="4">Agent</th>
              <th key="4">Joined</th>
              <th key="5">Status</th>
              <th key="6">Action</th>
            </tr>
          </tfoot>
        </Table>


        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            Showing {activePage * sort + 1} to &nbsp;
            {users.length < (activePage + 1) * sort
              ? users.length
              : (activePage + 1) * sort}
            &nbsp;of {users.length} entries
          </div>
          <div className="dataTables_paginate paging_simple_numbers">
            <Pagination
              className="pagination-primary pagination-circle"
              size="lg"
            >
              <li
                className="page-item page-indicator "
                onClick={() => activePage > 1 && onClick(activePage - 1)}
              >
                <Link className="page-link" to="#">
                  <i className="la la-angle-left" />
                </Link>
              </li>
              {pagination.map((number, i) => (
                <Pagination.Item
                  key={"page-" + i}
                  className={activePage === i ? "active" : ""}
                  onClick={() => onClick(i)}
                >
                  {number}
                </Pagination.Item>
              ))}
              <li
                className="page-item page-indicator"
                onClick={() =>
                  activePage + 1 < pagination.length && onClick(activePage + 1)
                }
              >
                <Link className="page-link" to="#">
                  <i className="la la-angle-right" />
                </Link>
              </li>
            </Pagination>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTable;
